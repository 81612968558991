
class FacebookTrack {
    // Refers to the hub facebook tracking FacebookUtils.onProductSelected
    static productAddToCart = (price, code) => {
        try{
            if (window.hasOwnProperty('fbq')) {
                if(price > 0){
                    window.fbq('track', 'AddToCart', {
                        content_type: code,
                        content_ids: code,
                        value: price,
                        currency: 'USD'
                    });
                }
            }
        }catch(err){
            /* istanbul ignore next */
            console.warn("Error tracking onProductSelected", err);
        }
    };

    // Refers to the hub facebook tracking FacebookUtils.onTrialActivated
    static onTrialActivated = (type, isCCRequiredTrial, trialValue) => {
        if (typeof isCCRequiredTrial === 'undefined') {
            isCCRequiredTrial = false;
        }
        if (typeof trialValue === 'undefined' || !isCCRequiredTrial) {
            trialValue = '0.00';
        }
        try{
            if (window.hasOwnProperty('fbq')) {
                window.fbq('track', 'StartTrial', {
                    content_category: 'trial',
                    content_name: type,
                    value: trialValue,
                    currency: 'USD',
                    predicted_ltv: trialValue
                });
            }
        }catch(err){
            /* istanbul ignore next */
            console.warn("Error tracking onTrialActivated", err);
        }
    };

    // Refers to the hub facebook tracking FacebookUtils.onCheckoutClicked
    static initiateCheckout = (price, code) => {
        try{
            if (window.hasOwnProperty('fbq')) {
                if(price > 0){
                    window.fbq('track', 'InitiateCheckout', {
                        content_type: code,
                        content_ids: code,
                        value: price,
                        currency: 'USD'
                    });
                }
            }
        }catch(err){
            /* istanbul ignore next */
            console.warn("Error tracking ConfirmAndPay", err);
        }
    };

    // Refers to the hub facebook tracking FacebookUtils.onPurchaseCompleted
    static registerPurchase = (price, code, orderId) => {
        if (window.hasOwnProperty('fbq')) {
            if(price > 0){
                window.fbq('track', 'Purchase', {
                    content_type: code,
                    content_ids: code,
                    value: price,
                    currency: 'USD'
                });
            }
        }
    };

    // Refers to the hub facebook tracking FacebookUtils.onCompleteRegistration
    static onCompleteRegistration = (price, signUpUrl = "") => {
        try{
            if (window.hasOwnProperty('fbq')) {
                let content_name = decodeURIComponent(signUpUrl);
                if(content_name === "/index.html"){
                    content_name = "/";
                }

                let trackPrice = price;
                if(typeof trackPrice === "undefined" || trackPrice === null || trackPrice === 0) {
                    trackPrice = 0.00;
                }

                window.fbq('track', 'CompleteRegistration', {
                    content_name: content_name,
                    value: trackPrice,
                    currency: 'USD'
                });
            }
        }catch(err){
            /* istanbul ignore next */
            console.warn("Error tracking ConfirmAndPay", err);
        }
    };
}

export default FacebookTrack;