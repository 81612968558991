const LocalStorageHelper = {
    get(key) {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        try{
            return JSON.parse(localStorage.getItem(key))
        } catch (e) {
            /* istanbul ignore next */
            return {}
        }
    },
    set(key, json) {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        try{
            localStorage.setItem(key, JSON.stringify(json));
        } catch (e) { }
    },
    delete(key) {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        try{
            localStorage.removeItem(key);
        } catch (e) { }
    },
    update(storageKey, updateKey, newValue) {
        try{
            let object = {}, storeValue = localStorage.getItem(storageKey);
            if(storeValue !== null) {
                object = JSON.parse(localStorage.getItem(storageKey));

                if (object.hasOwnProperty(updateKey)) {
                    object[updateKey] = newValue;
                } else {
                    object[updateKey] = newValue
                }
            } else {
                object[updateKey] = newValue;
            }

            this.set(storageKey, object);
        } catch (e) { }
    }
};

export default LocalStorageHelper;