import {APP_BROWSER_ROUTES, APP_EXTERNAL_URLS, APP_HASH_ROUTES, HASH_SUB_PAGE_DELIMITER} from "../constants/AppGlobal";
import {isExtensionFlow, isObjectEmpty, loadedInIframe} from "./GeneralHelper";
import queryString from 'query-string';

class URLHelper {

    static getUrlParams = (props) => {
        if (props.location.search) {
            return queryString.parse(props.location.search);
        }

        if (props.location.hash && props.location.hash.indexOf('?') !== -1) {
            const search = props.location.hash.split('?');

            return queryString.parse(search[1]);
        }

        return {};
    };

    static getUrlParamValue = (props, param) => {
        let arrSearch = URLHelper.getUrlParams(props);
        if (typeof arrSearch[param] !== 'undefined') {
            return arrSearch[param];
        }
        return null;
    };

    static getSearchParamsAsString = (props) => {
        if (props.location.hash && props.location.hash.indexOf('?') !== -1) {
            return "?" + props.location.hash.split('?')[1]
        }

        if (props.location.search) {
            return props.location.search;
        }

        return '';
    };

    static goTo = (url, history, state) => {
        if (isExtensionFlow()) {
            if (url.indexOf(APP_EXTERNAL_URLS.RCE_EXIT_PAGE) === -1) {
                // chrome extension flow needs these params
                url = URLHelper.addUrlSearchParamToUrl(url, 'origin=rce');
                url = URLHelper.addUrlSearchParamToUrl(url, 'flow=extension');
                url = URLHelper.addUrlSearchParamToUrl(url, 'avoidRedirecting=true');
            }
            // for extension flow, and upgrade path, we replace hash with browser routes
            url = url.replace(APP_HASH_ROUTES.CHECKOUT_PAGE, APP_BROWSER_ROUTES.CHECKOUT_SUMMARY_PAGE);
        } else if (loadedInIframe()) {
            // for upgrade paths that are not chrome extension flow
            // we replace the browser routing with the hash routing
            url = url.replace(APP_BROWSER_ROUTES.CHECKOUT_SUMMARY_PAGE, APP_HASH_ROUTES.CHECKOUT_PAGE);
        }
        if ((url.indexOf("/app") === -1 || url.indexOf("/hub") === -1) && url.indexOf(APP_HASH_ROUTES.UPGRADES_DISABLED) === -1) {
            // only adding parameters when the user stays in the application
            url = URLHelper.addUrlSearchParamToUrl(url, 'flowParameters=' + URLHelper.getUrlParamValueFromWindowObj(window.location,'flowParameters'));
        }
        url = URLHelper.addUrlSearchParamToUrl(url, 'essential=true');
        url = URLHelper.addUrlSearchParamToUrl(url, 'modal=true');

        if (typeof history !== "undefined" && history.hasOwnProperty('push')) {
            if (loadedInIframe() === true && window.location.href.indexOf('modal=true') !== -1) {
                URLHelper.replaceGoTo(url, history);
            } else {
                if (state) {
                    history.push(url, state);
                } else {
                    history.push(url);
                }
            }
            return;
        }

        window.location.href = url;
    };

    /**
     * @param {String} url
     * @param {String} paramString
     * eg. paramString='essential=true'
     *
     * @returns {String}
     */
    static addUrlSearchParamToUrl = (url, paramString) => {
        // we're adding flow parameters only if it is not there
        if (paramString.indexOf("flowParameters") !== -1 && url.indexOf("flowParameters") !== -1) {
            return url;
        }
        if (paramString.indexOf('&') !== -1) {
            const paramParts = paramString.split('&');
            if (paramParts.length > 0) {
                paramParts.forEach((part, index) => {
                    if (part.indexOf('=') !== -1) {
                        const param = part.split('=');
                        const paramName = param[0];
                        const paramValue = param[1];
                        if (url.indexOf(paramName + "=") === -1) {
                            url = URLHelper.addParamToUrl(url, part);
                        } else {
                            url = url.replace(paramName + "=" + paramValue, part);
                        }
                    }
                });
            }
        } else {
            url = URLHelper.addParamToUrl(url, paramString);
        }

        return url;
    };

    static addParamToUrl = (url, paramString) => {
        if (window.location.href.indexOf(paramString) !== -1) {
            if (url.indexOf(paramString) === -1) {
                if (url.indexOf('?') === -1) {
                    url += '?' + paramString;
                } else {
                    url += '&' + paramString;
                }
            }
        }

        return url;
    }

    static replaceGoTo = (url, history) => {
        if (isExtensionFlow()) {
            // chrome extension flow needs these params
            url = URLHelper.addUrlSearchParamToUrl(url, 'flow=extension');
            url = URLHelper.addUrlSearchParamToUrl(url, 'avoidRedirecting=true');
        }
        if ((url.indexOf("/app") === -1 || url.indexOf("/hub") === -1) && url.indexOf(APP_HASH_ROUTES.UPGRADES_DISABLED) === -1) {
            // only adding parameters when the user stays in the application
            url = URLHelper.addUrlSearchParamToUrl(url, 'flowParameters=' + URLHelper.getUrlParamValueFromWindowObj(window.location,'flowParameters'));
        }
        history.replace(url);
    };

    static gotToNewWindow = (url, target) => {
        window.open(url, target);
    };

    static getHash = (hash) => {
        let realHash = hash.replace('#', '');

        if (realHash.indexOf('?') !== -1) {
            realHash = realHash.substr(0, realHash.indexOf('?'));
        }

        if (realHash.indexOf('&') !== -1) {
            realHash = realHash.substr(0, realHash.indexOf('&'));
        }

        return realHash;
    };

    static getRealHash = (hash) => {
        let realHash = hash.replace('#', '');

        if (realHash.indexOf('?') !== -1) {
            realHash = realHash.substr(0, realHash.indexOf('?'));
        }

        if (realHash.indexOf(HASH_SUB_PAGE_DELIMITER) !== -1) {
            realHash = realHash.substr(0, realHash.indexOf(HASH_SUB_PAGE_DELIMITER));
        }

        if (realHash.indexOf('#') !== -1) {
            realHash = realHash.substr(0, realHash.indexOf('#'));
        }

        return realHash
    };

    static getSearchParamsFromHash = (hash, withoutParam) => {
        if (hash.indexOf('?') !== -1) {
            let search = hash.split('?'),
                params = queryString.parse(search[1]),
                newParams = {}, key;

            if(!isObjectEmpty(params)) {
                for (key in params) {
                    if(Object.prototype.hasOwnProperty.call(params,key)) {
                        if(typeof withoutParam !== "undefined" && key !== withoutParam) {
                            newParams[key] = params[key];
                        } else if(typeof withoutParam === "undefined") {
                            newParams[key] = params[key];
                        }
                    }
                }
            }

            if(!isObjectEmpty(newParams)) {
                return queryString.stringify(newParams, {encode: false});
            }
        }

        return "";
    };

    static getSearchParameterValue = (param) => {
        let strParams = URLHelper.getSearchParamsFromHash(window.location.hash);
        let paramsObj = queryString.parse(strParams, { parseBooleans: true, parseNumbers: true });

        if (paramsObj && paramsObj[param]) {
            return paramsObj[param];
        } else {
            return null;
        }
    }

    static getUrlParamsFromWindowObj = (location) => {
        if (location.search && location.search.length) {
            return queryString.parse(location.search);
        }

        if (location.hash && location.hash.indexOf('?') !== -1) {
            const search = location.hash.split('?');

            return queryString.parse(search[1]);
        }

        return {};
    };

    static getUrlParamValueFromWindowObj = (location, param) => {
        if (location.search && location.search.length) {
            const urlParams = queryString.parse(location.search);
            if (urlParams[param]) {
                return urlParams[param];
            }
        }

        if (location.hash && location.hash.indexOf('?') !== -1) {
            const search = location.hash.split('?');

            const urlParams = queryString.parse(search[1]);
            if (urlParams[param]) {
                return urlParams[param];
            }
        }

        return null;
    }
}

export default URLHelper;
