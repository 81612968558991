import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const {Types, Creators} = createActions({
    verifyEmailCode: {},
    verifyEmailCodeSuccess: null,
    verifyEmailCodeFailure: null,

    generateCode: {},
    generateCodeSuccess: null,
    generateCodeFailure: null,

    convertToFree: {},
    convertToFreeSuccess: null,
    convertToFreeFailure: null,

    cancelTrial: {},
    cancelTrialSuccess: null,
    cancelTrialFailure: null,

    verifyCoupon: {},
    verifyCouponSuccess: null,
    verifyCouponFailure: null,

    leaveInstance: {},
    leaveInstanceSuccess: null,
    leaveInstanceFailure: null,

    applyCoupon: {},
    applyCouponSuccess: null,
    applyCouponFailure: null,
});

export const ActivationTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
    emailVerified: false,
    error: false,
    generatedNewCode: false,
    cancelledAccount: false,
    convertAccountToFree: false,
    convertAccountToFreeError: false,
    loading: false,
    verifyCouponSuccess: null,
    verifyCouponInstanceName: "",
    leaveInstanceSuccess: null,
    applyCouponSuccess: null,
    applyCouponReason: "",
});

/* Selectors */
export const ActivationSelectors = {};

export const verifyEmailCodeReducer = (state) => {
    return {
        ...state,
    };
};
export const verifyEmailCodeSuccessReducer = (state, action) => {
    const {emailVerified} = action;
    return {
        ...state,
        emailVerified: emailVerified,
    };
};
export const verifyEmailCodeFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        error: error,
    };
};

export const generateCodeReducer = (state) => {
    return {
        ...state,
    };
};
export const generateCodeSuccessReducer = (state, action) => {
    const {generatedNewCode} = action;
    return {
        ...state,
        generatedNewCode: generatedNewCode,
        error: false,
    };
};
export const generateCodeFailureReducer = (state, action) => {
    const {error} = action;
    return {
        ...state,
        error: error,
    };
};

export const convertToFreeReducer = (state) => {
    return {
        ...state,
    };
};
export const convertToFreeSuccessReducer = (state, action) => {
    const {convertAccountToFree} = action;
    return {
        ...state,
        convertAccountToFree: convertAccountToFree,
        convertAccountToFreeError: false,
    };
};
export const convertToFreeFailureReducer = (state, action) => {
    const {error} = action;
    return {
        ...state,
        convertAccountToFreeError: error,
    };
};

export const cancelTrialReducer = (state) => {
    return {
        ...state,
    };
};
export const cancelTrialSuccessReducer = (state, action) => {
    return {
        ...state,
        cancelledAccount: true,
    };
};
export const cancelTrialFailureReducer = (state, action) => {
    return {
        ...state,
        cancelledAccount: false
    };
};

export const verifyCouponReducer = (state) => {
    return {
        ...state,
        verifyCouponSuccess: null,
        verifyCouponInstanceName: "",
    };
};
export const verifyCouponSuccessReducer = (state, action) => {
    const {verifyCouponInstanceName} = action;
    return {
        ...state,
        verifyCouponSuccess: true,
        verifyCouponInstanceName: verifyCouponInstanceName,
    };
};
export const verifyCouponFailureReducer = (state, action) => {
    return {
        ...state,
        verifyCouponSuccess: false,
    };
};

export const leaveInstanceReducer = (state) => {
    return {
        ...state,
        leaveInstanceSuccess: null,
    };
};
export const leaveInstanceSuccessReducer = (state, action) => {
    return {
        ...state,
        leaveInstanceSuccess: true,
    };
};
export const leaveInstanceFailureReducer = (state, action) => {
    return {
        ...state,
        leaveInstanceSuccess: false,
    };
};

export const applyCouponReducer = (state) => {
    return {
        ...state,
        applyCouponSuccess: null,
    };
};
export const applyCouponSuccessReducer = (state, action) => {
    const {reason} = action;
    return {
        ...state,
        applyCouponSuccess: true,
        applyCouponReason: reason
    };
};
export const applyCouponFailureReducer = (state, action) => {
    return {
        ...state,
        applyCouponSuccess: false,
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.VERIFY_EMAIL_CODE]: verifyEmailCodeReducer,
    [Types.VERIFY_EMAIL_CODE_SUCCESS]: verifyEmailCodeSuccessReducer,
    [Types.VERIFY_EMAIL_CODE_FAILURE]: verifyEmailCodeFailureReducer,

    [Types.GENERATE_CODE]: generateCodeReducer,
    [Types.GENERATE_CODE_SUCCESS]: generateCodeSuccessReducer,
    [Types.GENERATE_CODE_FAILURE]: generateCodeFailureReducer,

    [Types.CONVERT_TO_FREE]: convertToFreeReducer,
    [Types.CONVERT_TO_FREE_SUCCESS]: convertToFreeSuccessReducer,
    [Types.CONVERT_TO_FREE_FAILURE]: convertToFreeFailureReducer,

    [Types.CANCEL_TRIAL]: cancelTrialReducer,
    [Types.CANCEL_TRIAL_SUCCESS]: cancelTrialSuccessReducer,
    [Types.CANCEL_TRIAL_FAILURE]: cancelTrialFailureReducer,

    [Types.VERIFY_COUPON]: verifyCouponReducer,
    [Types.VERIFY_COUPON_SUCCESS]: verifyCouponSuccessReducer,
    [Types.VERIFY_COUPON_FAILURE]: verifyCouponFailureReducer,

    [Types.APPLY_COUPON]: applyCouponReducer,
    [Types.APPLY_COUPON_SUCCESS]: applyCouponSuccessReducer,
    [Types.APPLY_COUPON_FAILURE]: applyCouponFailureReducer,

    [Types.LEAVE_INSTANCE]: leaveInstanceReducer,
    [Types.LEAVE_INSTANCE_SUCCESS]: leaveInstanceSuccessReducer,
    [Types.LEAVE_INSTANCE_FAILURE]: leaveInstanceFailureReducer,

});
