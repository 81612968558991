import React from "react";
import { Route } from "react-router-dom";

const DefaultRoute = ({ component: C, layout: Layout, layoutProps,  props: cProps, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => <Layout {...layoutProps}><C {...props} {...cProps} /></Layout>}
        />
    );
};

export default DefaultRoute;