import productDescriptions from '../services/api/fixture/plansCardsDescriptions';

class EssentialsUpgrade {
    hasEssentials = false;

    code = "";
    name = "";
    subTitle = "";
    productId = 0;
    description = [];
    price = 0;
    capacity = "";
    multiUser = false;
    recurring = false;
    seatPrice = 0;
    includedSeats = 0;
    includedGb = 0;
    includedHrs = 0.0;
    currentPlan = false;
    hoursPerSeat = 0;
    gbPerSeat = 0;
    premiumMusic = false;
    productDetails = "";
    vertical = "";
    termDuration = 1;
    essentials = false;
    essentialsPrice = 0.00;

    constructor(essentialsUpgrade) {
        if(essentialsUpgrade && essentialsUpgrade.hasOwnProperty("code")) {
            this.hasEssentials = true;

            this.code = essentialsUpgrade.hasOwnProperty("code") ? essentialsUpgrade.code : this.code;
            this.name = essentialsUpgrade.hasOwnProperty("name") ? essentialsUpgrade.name : this.name;
            this.subTitle = essentialsUpgrade.hasOwnProperty("subTitle") ? essentialsUpgrade.subTitle : this.subTitle;
            this.productId = essentialsUpgrade.hasOwnProperty("productId") ? essentialsUpgrade.productId : this.productId;
            this.description = essentialsUpgrade.hasOwnProperty("description") ? this.normalizeDescription(essentialsUpgrade.description, this.code) : this.description;
            this.price = essentialsUpgrade.hasOwnProperty("price") ? essentialsUpgrade.price : this.price;
            this.capacity = essentialsUpgrade.hasOwnProperty("capacity") ? essentialsUpgrade.capacity : this.capacity;
            this.multiUser = essentialsUpgrade.hasOwnProperty("multiUser") ? essentialsUpgrade.multiUser : this.multiUser;
            this.recurring = essentialsUpgrade.hasOwnProperty("recurring") ? essentialsUpgrade.recurring : this.recurring;
            this.seatPrice = essentialsUpgrade.hasOwnProperty("seatPrice") ? essentialsUpgrade.seatPrice : this.seatPrice;
            this.includedSeats = essentialsUpgrade.hasOwnProperty("includedSeat") ? essentialsUpgrade.includedSeat : this.includedSeat;
            this.includedGb = essentialsUpgrade.hasOwnProperty("includedGb") ? essentialsUpgrade.includedGb : this.includedGb;
            this.includedHrs  = essentialsUpgrade.hasOwnProperty("includedHrs") ? essentialsUpgrade.includedHrs : this.includedHrs;
            this.currentPlan = essentialsUpgrade.hasOwnProperty("currentPlan") ? essentialsUpgrade.currentPlan : this.currentPlan;
            this.hoursPerSeat = essentialsUpgrade.hasOwnProperty("hoursPerSeat") ? essentialsUpgrade.hoursPerSeat : this.hoursPerSeat;
            this.gbPerSeat = essentialsUpgrade.hasOwnProperty("gbPerSeat") ? essentialsUpgrade.gbPerSeat : this.gbPerSeat;
            this.premiumMusic = essentialsUpgrade.hasOwnProperty("premiumMusic") ? essentialsUpgrade.premiumMusic : this.premiumMusic;
            this.productDetails = essentialsUpgrade.hasOwnProperty("productDetails") ? essentialsUpgrade.productDetails : this.productDetails;
            this.vertical = essentialsUpgrade.hasOwnProperty("vertical") ? essentialsUpgrade.vertical : this.vertical;
            this.termDuration = essentialsUpgrade.hasOwnProperty("termDuration") ? essentialsUpgrade.termDuration : this.termDuration;
            this.essentials = essentialsUpgrade.hasOwnProperty("essentials") ? essentialsUpgrade.essentials : this.essentials;
            this.essentialsPrice = essentialsUpgrade.hasOwnProperty("essentialsPrice") ? essentialsUpgrade.essentialsPrice : this.essentialsPrice;
        }
    }

    normalizeDescription = (description, productCode) => {
        if ( typeof description === 'string' && typeof productCode !== 'undefined' && productDescriptions && productDescriptions.hasOwnProperty(productCode)) {
            return productDescriptions[productCode];

        }
        return description;
    };

    getIcon = (item) => {

        function contains(str) {
            return item.toLowerCase().indexOf(str) > -1;
        }

        if (contains("resolution") || contains("720")) {
            return "tv";
        } else if (contains("publish") || contains("hr ")) {
            return "access_time";
        } else if (contains("motion")) {
            return "font_download";
        } else if (contains("premium")) {
            return "movie_filter";
        } else if (contains("vip")) {
            return "contact_mail";
        } else if (contains("advanced")) {
            return "loyalty";
        } else if (contains("storage") || contains("gb")) {
            return "storage";
        } else if (contains("licenses")) {
            return "person";
        } else if (contains("seat pack")) {
            return "account_circle";
        } else if (contains("templates")) {
            return "photo_library";
        } else if (contains("business")) {
            return "account_circle";
        } else if (contains("collaboration")) {
            return "group_add";
        } else if (contains("management")) {
            return "dvr";
        } else if (contains("single")) {
            return "person";
        } else if (contains("team")) {
            return "people";
        } else if (contains("social")) {
            return "share";
        } else if (contains("square")) {
            return "aspect_ratio";
        } else if (contains("licensed")) {
            return "star";
        }

        return "";
    };

    setHasEssentials = (hasEssentials) => {
        this.hasEssentials = hasEssentials;
    };

    getHasEssentials = () => {
        return this.hasEssentials;
    };

    setCode = (code) => {
        this.code = code;
    };

    getCode = () => {
        return this.code;
    };

    setName = (name) => {
        this.name = name;
    };

    getName = () => {
        return this.name;
    };

    setSubTitle = (subTitle) => {
        this.subTitle = subTitle;
    };

    getSubTitle = () => {
        return this.subTitle;
    };

    setProductId = (productId) => {
        this.productId = productId;
    };

    getProductId = () => {
        return this.productId;
    };

    setDescription = (description) => {
        this.description = description;
    };

    getDescription = () => {
        return this.description;
    };

    setPrice = (price) => {
        this.price = price;
    };

    getPrice = () => {
        return this.price;
    };

    setCapacity = (capacity) => {
        this.capacity = capacity;
    };

    getCapacity = () => {
        return this.capacity;
    };

    setMultiUser = (multiUser) => {
        this.multiUser = multiUser;
    };

    getMultiUser = () => {
        return this.multiUser;
    };

    setRecurring = (recurring) => {
        this.recurring = recurring;
    };

    getRecurring = () => {
        return this.recurring;
    };

    setSeatPrice = (seatPrice) => {
        this.seatPrice = seatPrice;
    };

    getSeatPrice = () => {
        return this.seatPrice;
    };

    setIncludedSeats = (includedSeats) => {
        this.includedSeats = includedSeats;
    };

    getIncludedSeats = () => {
        return this.includedSeats;
    };

    setIncludedHrs = (includedHrs) => {
        this.includedHrs = includedHrs;
    };

    getIncludedHrs = () => {
        return this.includedHrs;
    };

    setCurrentPlan = (currentPlan) => {
        this.currentPlan = currentPlan;
    };

    getCurrentPlan = () => {
        return this.currentPlan;
    };

    setHoursPerSeat = (hoursPerSeat) => {
        this.hoursPerSeat = hoursPerSeat;
    };

    getHoursPerSeat = () => {
        return this.hoursPerSeat;
    };

    setGbPerSeat = (gbPerSeat) => {
        this.gbPerSeat = gbPerSeat;
    };

    getGbPerSeat = () => {
        return this.gbPerSeat;
    };

    setPremiumMusic = (premiumMusic) => {
        this.premiumMusic = premiumMusic;
    };

    getPremiumMusic = () => {
        return this.premiumMusic;
    };

    setProductDetails = (productDetails) => {
        this.productDetails = productDetails;
    };

    getProductDetails = () => {
        return this.productDetails;
    };

    setVertical = (vertical) => {
        this.vertical = vertical;
    };

    getVertical = () => {
        return this.vertical;
    };

    setTermDuration = (termDuration) => {
        this.termDuration = termDuration;
    };

    getTermDuration = () => {
        return this.termDuration;
    };

    setEssentials = (essentials) => {
        this.essentials = essentials;
    };

    getEssentials = () => {
        return this.essentials;
    };

    setEssentialsPrice = (essentialsPrice) => {
        this.essentialsPrice = essentialsPrice;
    };

    getEssentialsPrice = () => {
        return this.essentialsPrice;
    };

}

export default EssentialsUpgrade;