import React from "react"
import {
    APP_HASH_PATH,
    APP_HASH_PATH_ROUTE
} from "../constants/AppGlobal";
import URLHelper from "./../helpers/URLHelper"

// Async Component
import asyncComponent from "../components/asyncComponent/AsyncComponent";

// Route Type
import DefaultRoute from "./types/DefaultRoute";

// Layouts
import DefaultLayout from "../layouts/DefaultLayout";
import {sendUrlChangedPostMessage} from "../helpers/GeneralHelper";

// Pages
const AsyncNotFound = asyncComponent( () => import("../pages/notFound/NotFound"));
const AsyncPlansPage = asyncComponent( () => import("../pages/plans/PlansPageVariation"));

const pathComponent = [
    {
        'hash': APP_HASH_PATH.PLANS_PAGE,
        'component': AsyncPlansPage,
        'pageName': "Plans Page",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.CHECKOUT_PAGE,
        'component': asyncComponent( () => import("../pages/checkout/CheckoutPage")),
        'pageName': "Checkout Summary",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {
            'checkoutStep': "0",
        }
    },
    {
        'hash': APP_HASH_PATH.PAYMENT_COMPLETED,
        'component': asyncComponent( () => import("../pages/checkout/CheckoutCompletedPage")),
        'pageName': "Payment Completed",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },{
        'hash': APP_HASH_PATH.PAYPAL_COMPLETED,
        'component': asyncComponent( () => import("../pages/checkout/PaypalCompletedPage")),
        'pageName': "Payment Completed",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.FREE_ACCOUNT_COMPLETED,
        'component': asyncComponent( () => import("../pages/checkout/CheckoutCompletedPage")),
        'pageName': "Free account created",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.EDU_TRIAL_ACCOUNT_COMPLETED,
        'component': asyncComponent( () => import("../pages/checkout/CheckoutCompletedPage")),
        'pageName': "Education Trial Account completed",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.BIZ_TRIAL_ACCOUNT_COMPLETED,
        'component': asyncComponent( () => import("../pages/checkout/CheckoutCompletedPage")),
        'pageName': "Business Trial Account completed",
        'layout': DefaultLayout,
        'exact': true,
        'hasHeader': false,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.EMAIL_ACTIVATION,
        'component': asyncComponent( () => import("../pages/activation/EmailVerificationPage")),
        'pageName': "Email Verification",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.ADDITIONAL_SEATS,
        'component': asyncComponent( () => import("../pages/modals/AdditionalSeatsPage")),
        'pageName': "Additional seats",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.ESSENTIALS,
        'component': asyncComponent( () => import("../pages/modals/EssentialsPage")),
        'pageName': "Essentials",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.CANCEL_ESSENTIALS,
        'component': asyncComponent( () => import("../pages/modals/CancelEssentialsPage")),
        'pageName': "Essentials Cancel",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.CANCEL_TRIAL,
        'component': asyncComponent( () => import("../pages/modals/CancelTrialPage.js")),
        'pageName': "Trial Cancel",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
    {
        'hash': APP_HASH_PATH.UPGRADES_DISABLED,
        'component': asyncComponent( () => import("../pages/MobileSubscriptionPage.js")),
        'pageName': "Upgrades disabled",
        'hasHeader': false,
        'layout': DefaultLayout,
        'exact': true,
        'props': {}
    },
];

const getRouteByHash = (hash) => {
    let realHash = URLHelper.getRealHash(hash);

    return pathComponent.filter(function (route) {
        return route.hash === realHash
    })[0];
};

const appRoutesHash = ({childProps}) => {
    const  { location } = childProps;

    sendUrlChangedPostMessage(location);

    if(
        location.pathname === APP_HASH_PATH_ROUTE
        && location.hash === ""
    ) {
        return <DefaultRoute
            component={AsyncNotFound}
            layout={DefaultLayout}
            props={childProps}
            layoutProps={{
                pageName: "Not Found",
                hasHeader: false
            }}
        />;
    }


    let route = getRouteByHash(location.hash);

    if(typeof route === "undefined") {
        if(location.pathname !== APP_HASH_PATH_ROUTE) {
            return null
        }

        return <DefaultRoute
            component={AsyncNotFound}
            layout={DefaultLayout}
            props={childProps}
            layoutProps={{
                pageName: "Not Found",
                hasHeader: false
            }}
        />;
    }

    return  (
        <DefaultRoute
            path={APP_HASH_PATH_ROUTE}
            exact={route.hasOwnProperty('exact') ? route.exact : false}
            component={route.component}
            props={childProps}
            layout={route.layout}
            layoutProps={{
                pageName: route.pageName,
                hasHeader: route.hasHeader,
            }}
            {...route.props}
        />
    );
};

export default appRoutesHash;