import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const {Types, Creators} = createActions({
    initApp: {},
    initAppSuccess: null,
    initAppFailure: null
});

export const InitAppTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
    appLoaded: false,
    app: {},
    error: null
});

/* Selectors */
export const UserSelectors = {};

export const initAppReducer = (state) => {
    return {
        ...state,
    };
};

export const initAppSuccessReducer = (state, action) => {
    const { app } = action;
    return {
        ...state,
        appLoaded: true,
        app: app
    }
};

export const initAppFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        appLoaded: true,
        error: error
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.INIT_APP]: initAppReducer,
    [Types.INIT_APP_SUCCESS]: initAppSuccessReducer,
    [Types.INIT_APP_FAILURE]: initAppFailureReducer,
});