/* istanbul ignore file */
import configureStore from './CreateStore';
import rootSaga from '../sagas';

import reducers from './Reducer'

const idx = ( state = null ) => {
    let { store, sagasManager, sagaMiddleware } = configureStore(reducers, rootSaga, state);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./').reducers;
            store.replaceReducer(nextRootReducer);

            const newYieldedSagas = require('../sagas').default;
            sagasManager.cancel();
            sagasManager.done.then(() => {
                sagasManager = sagaMiddleware.run(newYieldedSagas)
            })
        })
    }

    return store
};

export default idx;