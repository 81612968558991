
class OrderSummary {
    total = 0;
    subTotal = 0;
    firstTermDiscount = 0;
    firstTermBasePrice = 0;
    prorateDiscount = 0;
    recurringPrice = 0;
    firstTermExtraSeatsPrice = 0;
    extraSeatsRecurringPrice = 0;
    currentExtraSeatsPrice = 0;
    combinedTaxRate = 0;
    taxRateTotal = 0;
    prorateAmount = 0;
    nonRecurringAddOnsPrice = 0;


    constructor(orderSummary) {
        if (orderSummary && orderSummary.hasOwnProperty('total')) {
            this.total = orderSummary.total;
            this.subTotal = orderSummary.subTotal;
            /* istanbul ignore else */
            if (orderSummary.hasOwnProperty('newSubscription')) {
                this.firstTermDiscount = orderSummary.newSubscription.firstTermDiscount;
                this.firstTermBasePrice = orderSummary.newSubscription.firstTermBasePrice;
                this.prorateDiscount = orderSummary.newSubscription.prorateDiscount;
                this.recurringPrice = orderSummary.newSubscription.recurringBasePrice;
                this.firstTermExtraSeatsPrice = orderSummary.newSubscription.firstTermExtraSeatsPrice;
                this.extraSeatsRecurringPrice = orderSummary.newSubscription.extraSeatsRecurringPrice;
                this.nonRecurringAddOnsPrice = orderSummary.newSubscription.hasOwnProperty('nonRecurringAddOnsPrice') ? orderSummary.newSubscription.nonRecurringAddOnsPrice : 0;
            }
            if (this.firstTermBasePrice !== 0 && this.recurringPrice !== 0) {
                this.subTotal = this.recurringPrice;
                this.subTotal = Math.round(this.subTotal * 100) / 100;
            }
            if (orderSummary.hasOwnProperty('proration')) {
                this.prorateAmount = orderSummary.proration.prorateAmount;
                if (orderSummary.proration.hasOwnProperty('currentExtraSeatsPrice')) {
                    this.currentExtraSeatsPrice = orderSummary.proration.currentExtraSeatsPrice;
                }
            }
            /* istanbul ignore else */
            if (orderSummary.hasOwnProperty('taxRates') && orderSummary.hasOwnProperty('salesTax')) {
                let combinedTaxRate = 0;
                orderSummary.taxRates.map((item, i) => {
                    switch (item.type) {
                        case "COMBINED":
                            combinedTaxRate = item.hasOwnProperty('taxAmount') ? item.taxAmount : 0;
                            break;
                        default:
                            break;
                    }
                    return null;
                });
                this.combinedTaxRate = combinedTaxRate;
                this.taxRateTotal = orderSummary.salesTax;
            }
        }
    }

    setTotal = (total) => {
        this.total = total;
    };

    getTotal = () => {
        return this.total;
    };

    setFirstTermDiscount = (firstTermDiscount) => {
        this.firstTermDiscount = firstTermDiscount;
    };

    getFirstTermDiscount = () => {
        return this.firstTermDiscount;
    };

    setFirstTermBasePrice = (firstTermBasePrice) => {
        this.firstTermBasePrice = firstTermBasePrice;
    };

    getFirstTermBasePrice = () => {
        return this.firstTermBasePrice;
    };

    setProrateDiscount = (prorateDiscount) => {
        this.prorateDiscount = prorateDiscount;
    };

    getProrateDiscount = () => {
        return this.prorateDiscount;
    };

    setRecurringPrice = (recurringPrice) => {
        this.recurringPrice = recurringPrice;
    };

    getRecurringPrice = () => {
        return this.recurringPrice;
    };

    setFirstTermExtraSeatsPrice = (firstTermExtraSeatsPrice) => {
        this.firstTermExtraSeatsPrice = firstTermExtraSeatsPrice;
    };

    getFirstTermExtraSeatsPrice = () => {
        return this.firstTermExtraSeatsPrice;
    };

    setExtraSeatsRecurringPrice = (extraSeatsRecurringPrice) => {
        this.extraSeatsRecurringPrice = extraSeatsRecurringPrice;
    };

    getExtraSeatsRecurringPrice = () => {
        return this.extraSeatsRecurringPrice;
    };

    getCurrentExtraSeatsPrice = () => {
        return this.currentExtraSeatsPrice;
    }

    getTaxRateTotal = () => {
        return this.taxRateTotal;
    };

    getOrderSubtotal = () => {
        return this.subTotal;
    }

    getProrateAmount = () => {
        return this.prorateAmount;
    }

    getNonRecurringAddOnsPrice = () => {
        return this.nonRecurringAddOnsPrice;
    }
}

export default OrderSummary