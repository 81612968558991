import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import AppRoutersBrowser from "../routes/AppRoutesBrowser"
import {InitAppTypes} from "../redux/InitAppReducer";
import Track from "../services/track/Track";
import AppRoutesHash from "../routes/AppRoutesHash";
import {
    APP_EXTERNAL_URLS,
    APP_HASH_PATH_ROUTE,
    APP_ROUTES,
    APP_HASH_PATH,
    APP_HASH_ROUTES,
    RESPONSIVE_MOBILE_RESOLUTION,
    RESPONSIVE_TABLET_RESOLUTION, APP_BROWSER_ROUTES, WEVIDEO_PLANS
} from "../constants/AppGlobal";
import {
    isAuth,
    isInHub,
    accountUpgradesDisabled,
    hasAndroidSubscription,
    hasItunesSubscription,
    getInvalidTeacherEmail, shouldViewUpgrade, getInvalidEduEmail, activationRequired, getSelectedProduct
} from "../helpers/AppHelper";
import URLHelper from "../helpers/URLHelper";


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appLoaded: false,
            error: null,
            isTablet: false,
            isMobile: false,
        };

        Track.init();
    }

    componentDidMount() {
        this.props.initApp();
        setTimeout(this.resizeActions.bind(this), 100);
        window.addEventListener("resize", this.resizeActions.bind(this));
    };

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeActions.bind(this));
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.appLoaded !== prevState.appLoaded) {
            return ({appLoaded: nextProps.appLoaded})
        }

        if (nextProps.error !== prevState.error) {
            return ({error: nextProps.error})
        }

        return null;
    }

    resizeActions = () => {
        if(window.innerWidth >= RESPONSIVE_MOBILE_RESOLUTION && window.innerWidth < RESPONSIVE_TABLET_RESOLUTION) {
            if(!this.state.isTablet) {
                this.setState({
                    isTablet: true,
                    isMobile: false,
                });
            }
        } else if(window.innerWidth < RESPONSIVE_MOBILE_RESOLUTION) {
            if(!this.state.isMobile) {
                this.setState({
                    isTablet: false,
                    isMobile: true,
                });
            }
        } else {
            if(this.state.isTablet) {
                this.setState({
                    isTablet: false,
                });
            }

            if(this.state.isMobile) {
                this.setState({
                    isMobile: false,
                });
            }
        }
    };

    needsRedirectToPage = () => {
        if (this.props.location.pathname === APP_BROWSER_ROUTES.RCE_LANDING) {
            return !isAuth();
        }
        if (this.props.location.pathname === APP_ROUTES.INVITE_PAGE || this.props.location.pathname === APP_ROUTES.INVITE_PAGE_SLASH) {
            return false;
        }
        if(
            this.props.location.pathname !== APP_ROUTES.PLANS_PAGE
            && this.props.location.pathname !== APP_ROUTES.PLANS_SIGN_UP
            && this.props.location.pathname !== APP_ROUTES.HOMEPAGE
            && this.props.location.pathname !== APP_EXTERNAL_URLS.SIGN_IN
            && this.props.location.pathname !== APP_ROUTES.LOGIN_PAGE
            && this.props.location.pathname !== APP_ROUTES.SIGN_UP_PAGE
            && this.props.location.pathname !== APP_ROUTES.NEW_PASSWORD
            && this.props.location.pathname !== APP_BROWSER_ROUTES.SEGMENTATION_INFO_REDIRECT
            && this.props.location.pathname !== APP_BROWSER_ROUTES.TEMPLATE_REDIRECT
        ) {
            if(!isAuth()) {
                return true;
            }
        }
        if (!shouldViewUpgrade() && (getInvalidTeacherEmail() || getInvalidEduEmail()) && this.props.location.pathname !== APP_BROWSER_ROUTES.INVALID_EMAIL_PAGE) {
            if (isAuth() && activationRequired()) {
                let currentLocation = this.props.location.pathname + this.props.location.hash;
                if (currentLocation.indexOf(APP_HASH_ROUTES.PLANS_PAGE) === 0 || currentLocation.indexOf(APP_ROUTES.SIGN_UP_PAGE) === 0) {
                    return false;
                }
            }
            // this user wants to buy a teacher plan with an invalid email address
            // we send him to the invalid email info page
            const params = URLHelper.getUrlParams(this.props);
            let extraParams = '';
            if (params.validateEduEmail && params.validateEduEmail === 'true') {
                extraParams = '&validateEduEmail=true';
            }

            const selectedProductCode = getSelectedProduct();
            let p ="buy";
            if (selectedProductCode === WEVIDEO_PLANS.EDUCATION_TRIAL) {
                p = "trial";
            }

            URLHelper.goTo(
                APP_BROWSER_ROUTES.INVALID_EMAIL_PAGE +
                "?p=" + p +
                "&tier=education" +
                "&product=" + selectedProductCode +
                "&plan=" + selectedProductCode + extraParams
            );
            return true;
        }

        return false;
    };

    redirectToPage = () => {
        if (this.props.location.pathname === APP_BROWSER_ROUTES.RCE_LANDING && !isAuth()) {
            URLHelper.goTo(APP_EXTERNAL_URLS.SIGN_IN);
            return null;
        }
        if (!isAuth() && isInHub()) {
            if (this.props.location.pathname === APP_HASH_PATH_ROUTE) {
                let path = this.props.location.hash;
                if (path.length > 0) {
                    path = path.substring(1);
                }
                URLHelper.goTo(APP_EXTERNAL_URLS.SIGN_IN + "?redirectURL=/app?requestedPage=" + btoa(path));
                return null;
            }

            URLHelper.goTo(APP_EXTERNAL_URLS.SIGN_IN);
            return null;
        }
        return null;
    };

    render() {
        if (!this.state.appLoaded) {
            return null
        }

        let hasAccountUpgradesDisabled = false;
        if (isInHub() && accountUpgradesDisabled() && (hasAndroidSubscription() || hasItunesSubscription())) {
            hasAccountUpgradesDisabled = true;
            /*
             * Typically a user with a paid mobile (Google Play/iTunes)
             * subscription). Just display an error page.
             *
             * TODO: Refactor this once the segmentation stuff has been merged
             * in, since that branch seems to redo a lot of the redirecting done
             * in this method
             */
            if (this.props.location.hash.indexOf(APP_HASH_PATH.UPGRADES_DISABLED) === -1) {
                URLHelper.goTo(APP_HASH_ROUTES.UPGRADES_DISABLED, this.props.history);
                return null;
            }
        }

        if (hasAccountUpgradesDisabled === false && this.needsRedirectToPage()) {
            return this.redirectToPage();
        }

        const childProps = {
            ...this.props,
            isTablet: this.state.isTablet,
            isMobile: this.state.isMobile,
        };

        return (
            <Fragment>
                <AppRoutersBrowser childProps={{...childProps}}/>
                <AppRoutesHash childProps={{...childProps}}/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appLoaded: state.app.appLoaded,
        error: state.app.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initApp: () => {
            dispatch({type: InitAppTypes.INIT_APP});
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
