import {call, put, select} from "redux-saga/effects";
import {UserTypes} from "../redux/UserReducer";
import {LOCAL_STORAGE_APP_KEY} from "../constants/LocalStorage";
import * as InitAppHelper from "../helpers/InitAppHelper";
import {
    getProductOptionsCacheKey,
    getProductsDataFromCache,
    normalizeProducts,
    setCurrentProduct
} from "../helpers/ProductsHelper";
import DataStore from "../helpers/DataStore";
import {updateCheckoutUserContextActiveState} from "../helpers/InitAppHelper";

const getUser = function* (api, action) {
    try {
        const response = yield call(api.getUser);
        let user = response.data;

        yield put({type: UserTypes.GET_USER_SUCCESS, user: user});
    } catch (error) {
        yield put({type: UserTypes.GET_USER_FAILURE, error: error.message})
    }
};

const getUserContext = function* (api, action) {
    try {
        const initialContext = yield select(state => state.user);
        if(typeof initialContext !== "undefined" && initialContext.hasOwnProperty("context") && initialContext.context !== null) {
            yield put({type: UserTypes.GET_USER_CONTEXT_SUCCESS, context: initialContext.context})
        } else {
            const response = yield call(api.getUserContext);
            yield put({type: UserTypes.GET_USER_CONTEXT_SUCCESS, context: response.data})
        }

    } catch (error) {
        yield put({type: UserTypes.GET_USER_FAILURE, error: error.message})
    }
};

const getUserProductOptions = function* (api, action) {
    try {
        const { tier, noCache , trial } = action;
        let response = {};
        let productsData = getProductsDataFromCache(tier, trial, false, '', noCache);

        if (productsData !== null && productsData.hasOwnProperty('products')) {
            response = {
                status: 200,
                data: productsData
            };
        }
        if (productsData === null) {
            response = yield call(api.getUserProductOptions, tier, null, null, trial);
        }
        if (response.status === 200 && response.data.hasOwnProperty('products')) {
            const productsOptions = response.data;

            let cacheKey = getProductOptionsCacheKey(tier);
            DataStore.getInstance().set(cacheKey, productsOptions);

            if (productsOptions.hasOwnProperty('essentialsPlan')) {
                productsOptions.products.push(productsOptions.essentialsPlan);
            }

            if (productsOptions.hasOwnProperty('trialProduct')) {
                // productsOptions.trialProduct.ccRequiredForTrial = true;
                productsOptions.products.push(productsOptions.trialProduct);
            }

            /* istanbul ignore else */
            if(productsOptions.hasOwnProperty('priceTestingGroupValue')) {
                DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceTestingGroup', InitAppHelper.setPriceTestingGroup(productsOptions));
                DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceTestingGroupValue', InitAppHelper.setPriceTestingGroupValue(productsOptions));
                DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceCampaignKey', InitAppHelper.setPriceCampaignKey(productsOptions));
            }

            setCurrentProduct(productsOptions);
            productsOptions.products = normalizeProducts(productsOptions.products);

            // for unit test's output uncomment and fix with the following output from the console log
            // console.log(JSON.stringify(productsOptions));

            yield put({type: UserTypes.GET_USER_PRODUCT_OPTIONS_SUCCESS, options: productsOptions});
        } else {
            yield put({type: UserTypes.GET_USER_FAILURE, error: "Failed to get Products"})
        }
    } catch (error) {
        yield put({type: UserTypes.GET_USER_FAILURE, error: error.message})
    }
};

const setUserAttribute = function* (api, action) {
    try {
        const { key, value } = action;
        yield call(api.setUserAttribute, key, value);
    } catch (e) {
        console.log("Error setting user attribute", e);
    }
};

const activateAccount = function* (api, action) {
    try {
        const response = yield call(api.activateAccount);
        if (response.data.success) {
            // we update the datastore as soon as the account is activated
            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'activationRequired', !response.data.success);
            updateCheckoutUserContextActiveState(response.data.success);
        }
        yield put({type: UserTypes.ACTIVATE_ACCOUNT_SUCCESS, activateAccountSuccess: response.data.success})
    } catch (e) {
        yield put({type: UserTypes.ACTIVATE_ACCOUNT_FAILURE, error: e.message})
    }
};

const editUserDetails = function* (api, action) {
    const { user } = action;
    try {
        const response = yield call(api.editUserDetails, user);
        if(
            typeof response.data !== "undefined"
            && response.data.hasOwnProperty('success')
            && (
                response.data.success === "true"
                || response.data.success === true
            )
        ) {
            yield put({type: UserTypes.EDIT_USER_DETAILS_SUCCESS})
        } else {
            yield put({type: UserTypes.EDIT_USER_DETAILS_FAILURE})
        }
    } catch (e) {
        yield put({type: UserTypes.EDIT_USER_DETAILS_FAILURE})
    }
};

const deleteMembers = function* (api, action) {
    const {forgetMe, deleteOwner, reason, forgetOwner, deleteMembers} = action;

    try {
        const response = yield call(api.deleteMembers, forgetMe, deleteOwner, reason, forgetOwner, deleteMembers);
        if(
            typeof response.data !== "undefined"
            && response.data.hasOwnProperty('success')
            && (
                response.data.success === "true"
                || response.data.success === true
            )
        ) {
            yield put({type: UserTypes.DELETE_MEMBERS_SUCCESS})
        } else {
            yield put({type: UserTypes.DELETE_MEMBERS_FAILURE, deleteMembersErrorMessage: "Fail to get Delete Members!"})
        }
    } catch (e) {
        yield put({type: UserTypes.DELETE_MEMBERS_FAILURE, deleteMembersErrorMessage: e.message})
    }

};

const verifyTeacherEmail = function* (api, action) {
    try {
        const response = yield call(api.verifyTeacherEmail);

        if (response.data && response.data.hasOwnProperty('success')) {
            let invalidTeacherEmail = response.data.success === false
            // we update the datastore as soon as the account is activated
            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'invalidTeacherEmail', invalidTeacherEmail);
            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'teacherEmailWasValidated', true);
        }
        yield put({type: UserTypes.VERIFY_TEACHER_EMAIL_SUCCESS, verifyTeacherEmailSuccess: response.data.success})
    } catch (e) {
        yield put({type: UserTypes.VERIFY_TEACHER_EMAIL_FAILURE, error: e.message})
    }
};

const getUserInviteInfo = function* (api, action) {
    try {
        const { joinCode } = action;
        const response = yield call(api.getUserInviteInfo, joinCode);

        yield put({type: UserTypes.GET_USER_INVITE_INFO_SUCCESS, userInviteInfo: response.data});
    } catch (error) {
        yield put({type: UserTypes.GET_USER_INVITE_INFO_FAILURE, error: error.message})
    }
}
const useInviteCode = function* (api, action) {
    try {
        const { joinCode } = action;
        const response = yield call(api.useInviteCode, joinCode);

        let message = "";
        if (response.data.success === false && response.data.hasOwnProperty("failureReason")) {
            message = response.data.failureReason;
        }

        yield put({type: UserTypes.USE_INVITE_CODE_SUCCESS, useInviteCodeSuccess: response.data.success, useInviteCodeMessage: message});
    } catch (error) {
        yield put({type: UserTypes.USE_INVITE_CODE_FAILURE, error: error.message})
    }
}

export {
    getUser,
    getUserContext,
    getUserProductOptions,
    setUserAttribute,
    activateAccount,
    editUserDetails,
    deleteMembers,
    verifyTeacherEmail,
    getUserInviteInfo,
    useInviteCode,
};