/* The idea is to gather caption related stuff here, and at some point extend it to add support for localization if needed */ 
import {isClientUs} from "./AppHelper";

export const getPostalCodePlaceHolder = function() {
	return isClientUs() ? "ZIP" : "Postal code";
};

export const getPostalCodeLabel = function() {
	/* ZIP code is a US only term */
	return isClientUs() ? "ZIP" : "Postal";
};

export const getIncorrectZIPErrorMessage = function() {
	return "The " + (isClientUs() ? "ZIP" : "postal") + " code provided failed validation";
};

export const getPaymentError = function(code) {
	let ret = {
		code: "unknown",
		message: "Card declined. Please update card and try again."
	};
	
	if(code === "expired_card") {
		ret.message = "The card you provided is expired. Please try again with a valid card.";
		ret.code = code;
    } else if(code === "incorrect_cvc") {
    	ret.message = "You did not enter the correct CVC code for your card. The CVC code is the three or four digit number on the back of the card.";
    	ret.code = code;
    } else if(code === "card_declined") {
    	ret.message = "Your card was declined. Please reach out to your card provider for more information.";
    	ret.code = code;
    } else if(code === "processing_error") {
    	ret.message = "Oops, something went wrong. Please try again";
    	ret.code = code;
    } else if(code === "incorrect_zip") {
    	ret.message = getIncorrectZIPErrorMessage();
    	ret.code = code;
    }
	
	return ret;
};

export const getCurrencyPlaceHolder = function() {
	return isClientUs() ? "$" : "USD ";
};
