import CurrentProduct from "../models/CurrentProduct";
import Products from "../models/Products";
import Product from "../models/Product";
import {PLAN_SELECTED_TIER_BUSINESS, PLAN_SELECTED_TIER_EDUCATION} from "../constants/Plans";
import {
    LOCAL_STORAGE_OPTIONS_KEY_BUSINESS,
    LOCAL_STORAGE_OPTIONS_KEY_EDUCATION,
    LOCAL_STORAGE_OPTIONS_KEY_PERSONAL
} from "../constants/LocalStorage";
import productDescriptions from '../services/api/fixture/plansCardsDescriptions';
import DataStore from "./DataStore";
import {getIsExpiredTrial, isTrial, premiumTemplateProducts, showPremiumTemplates} from "./AppHelper";
import URLHelper from "./URLHelper";


export const getDurationMonths = (code, termDuration) => {
    if (code.indexOf("_6m") !== -1) {
        return 6;
    }

    if (termDuration === 1) {
        return 1;
    }

    return 12;
};

export const getMonthlyPrice = (price, code, termDuration) => {
    return Number((price / getDurationMonths(code, termDuration)).toFixed(2));
};

const getProductSubtitle = (code, includedSeats) => {
    // imported from PlansColumns_v3
    if (code === "wv_flex2") {
        return "Ocassional video creators";
    } else if (
        code === "wv_unlimited_1m" ||
        code === "wv_unlimited_12m" ||
        code === "wv_unloct17_12m" ||
        code === "wv_unloct17_1m"
    ) {
        return "Passionate video creators";
    } else if (code === "wv_bizoct17_12m" || code === "wv_bizoct17_1m") {
        return "Small businesses and marketing teams";
    } else if (code === "wv_bizindiv_12m" || code === "wv_bizindiv_1m") {
        return "Marketers and business owners";
    } else if (code === "wv_k12_6m" || code === "wv_k12_12m") {
        return includedSeats + " seat pack";
    } else {
        return "Memory makers";
    }
};

export const getCampaignDiscountPercent = (campaign, price) => {
    if (campaign.firstPurchaseDiscount) {
        return campaign.firstPurchaseDiscount * 100;
    } else if (campaign.firstTermDiscountOriginal) {
        return campaign.firstTermDiscountOriginal;
    } else if (getCampaignDiscount(price, campaign.price)) {
        return (getCampaignDiscount(price, campaign.price) / price) * 100;
    }

    return 0;
};

export const getCampaignDiscountedPrice = (campaignDiscountPercent, productPrice) => {
    return Math.round(productPrice * (100 - campaignDiscountPercent)) / 100;
}

export const getCampaignDiscount = (productPrice, campaignPrice) => {
    if (productPrice > 0 && campaignPrice > 0 && productPrice !== campaignPrice) {
        /* Specific amount discount */
        return productPrice - campaignPrice;
    }

    return 0;
};

export const isFlexPlan = (code) => {
    return code === "wv_flex2";
};

export const getFlexProduct = (products) => {
    let flexProduct = {};
    products.forEach((product) => {
        if (isFlexPlan(product.code)) {
            flexProduct = product;
        }
    });

    return flexProduct;
};

export const setCurrentProduct = (product) => {
    const currentProduct = CurrentProduct.getInstance();

    if (product.hasOwnProperty('currentPrice')) {
        currentProduct.setCurrentPrice(product.currentPrice);
    }

    if (product.hasOwnProperty('accountBalance')) {
        currentProduct.setAccountBalance(product.accountBalance);
    }

    if (product.hasOwnProperty('extraSeats')) {
        currentProduct.setExtraSeats(product.extraSeats);
    }

    if (product.hasOwnProperty('prorateRatio')) {
        currentProduct.setProrateRatio(product.prorateRatio);
    }

    if (product.hasOwnProperty('periodStart')) {
        currentProduct.setPeriodStart(product.periodStart);
    }

    if (product.hasOwnProperty('periodEnd')) {
        currentProduct.setPeriodEnd(product.periodEnd);
    }
};


//TODO: REMOVE the normalize functions when the API is done
const normalizeDescription = (description, productCode) => {

    if( typeof description === 'string' && typeof productCode !== 'undefined' && productDescriptions && productDescriptions.hasOwnProperty(productCode)) {
        return productDescriptions[productCode];
    }

    return description;
};

// Normalizing the products because we dont need all the date from the api
export const normalizeProducts = (products) => {
    let normalizedProducts = [];
    let availableTemplateProducts = premiumTemplateProducts();
    let includedContentTier = 0, reqIncludedHours = 0;
    // setting the minimum content tier value
    let ctv = URLHelper.getUrlParamValueFromWindowObj(window.location, "ctv");
    if (ctv !== null) {
        includedContentTier = ctv;
    }
    let reqInclMin = URLHelper.getUrlParamValueFromWindowObj(window.location, "minutes");
    if (reqInclMin !== null) {
        reqIncludedHours = Math.round(reqInclMin / 60 * 100);
        reqIncludedHours = reqIncludedHours / 100;
    }

    const allowedProductCodes = URLHelper.getUrlParamValueFromWindowObj(window.location, "productCodes");
    const resolutions = ["720", "1080", "2160"];
    const resolutionsObj = {
        "720": ["720p", "1080p", "2160p"],
        "1080": ["1080p", "2160p"],
        "2160": ["2160p"],
    };
    let allowedResolutions = [];
    resolutions.forEach(resolution => {
        let currentRes = URLHelper.getUrlParamValueFromWindowObj(window.location, resolution);
        if (currentRes !== null && currentRes === "true") {
            if (resolutionsObj.hasOwnProperty(resolution)) {
                resolutionsObj[resolution].forEach(res => {
                    if (allowedResolutions.includes(res) === false) {
                        allowedResolutions.push(res);
                    }
                });
            }
        }
    });

    if (products.length) {
        products.forEach((product, index) => {
            if (includedContentTier > 0 && product.includedContentTier < includedContentTier) {
                return;
            }
            if (product.hasOwnProperty("includedHrs") && product.includedHrs < reqIncludedHours) {
                return;
            }
            if (allowedProductCodes !== null && product.hasOwnProperty("code") && allowedProductCodes.indexOf(product.code) === -1) {
                return;
            }
            if (allowedResolutions.length && product.hasOwnProperty("maxPublishResolution")) {
                if (!allowedResolutions.includes(product.maxPublishResolution)) {
                    return;
                }
            }

            if (showPremiumTemplates() && availableTemplateProducts) {
                if (!availableTemplateProducts.includes(product.code) && !availableTemplateProducts.includes('all')) {
                    // we should not show this product on the plans page
                    // the user clicked a template that is available only in some plans
                    return;
                }
            }
            let price = product.price;
            if (product.hasOwnProperty('initialProduct')) {
                product.initialProduct = null;
            }
            let hasRecurringCampaign = false;

            if(product.hasOwnProperty('campaign')) {
                // Check if campaign is recurring
                if(product.campaign.promoPercent !== 0 && product.campaign.firstTermDiscount === 0) {
                    hasRecurringCampaign = true;
                }

                // When we have a campaign with price the base price of the product is the campaign price
                if(
                    product.campaign.hasOwnProperty('price')
                    && product.campaign.price > 0
                    && !hasRecurringCampaign
                ) {
                    price = product.campaign.price;
                }
            }

            let newProduct = {
                code: product.code,
                productId: product.productId,
                name: product.name,
                subTitle: getProductSubtitle(product.code, product.includedSeats),
                description: normalizeDescription(product.description, product.code),
                price: price,
                termDuration: product.termDuration,
                recurring: product.recurring,
                showYearly: product.hasOwnProperty('showYearly') ? product.showYearly : false,
                monthlyPrice: getMonthlyPrice(price, product.code, product.termDuration),
                monthlyDuration: getDurationMonths(product.code, product.termDuration),
                campaignDiscountPercent: product.hasOwnProperty("campaign") ? getCampaignDiscountPercent(product.campaign, price) : 0,
                hasRecurringCampaign: hasRecurringCampaign,
                essentials: product.essentials,
                currentPlan: product.currentPlan,
                seatPrice: product.seatPrice,
                savingsValue: 0,
                monthlyVersionPrice: 0,
                hasAnnualCampaignPrice: false,
                seats: product.hasOwnProperty('seats') ? product.seats : 0,
                extraSeats: product.hasOwnProperty('extraSeats') ? product.extraSeats : 0,
                essentialsPrice: product.hasOwnProperty('essentialsPrice') ? product.essentialsPrice : 0,
                campaign: product.hasOwnProperty('campaign') ? product.campaign : {},
                essentialsDowngrade: product.hasOwnProperty('essentialsDowngrade') ? product.essentialsDowngrade : {},
                includedSeats: product.includedSeats,
                essentialsUpgrade: product.hasOwnProperty("essentialsUpgrade") ? product.essentialsUpgrade : {},
                screenCapture: product.hasOwnProperty("screen-capture") ? product["screen-capture"] : false,
                dualCapture: product.hasOwnProperty("dual-capture") ? product["dual-capture"] : false,
                initialProduct: product,
                trialDuration: product.hasOwnProperty("trialDuration") ? product.trialDuration : -1,
                includedHrs: product.hasOwnProperty("includedHrs") ? product.includedHrs : null,
            };

            normalizedProducts.push(newProduct)
        });
    }

    for (let i = 0; i < products.length; i++) {
        for (let j = i + 1; j < products.length; j++) {
            if (showPremiumTemplates() && availableTemplateProducts) {
                if (!availableTemplateProducts.includes(products[j].code) && !availableTemplateProducts.includes('all')) {
                    break;
                }
            }
            if (products[i].code.indexOf("_plus_") !== -1 || products[j].code.indexOf("_plus_") !== -1) {
                break;
            }
            if (products[i].vertical === products[j].vertical && !products[i].hasOwnProperty('trialDuration') && !products[j].hasOwnProperty('trialDuration')) {
                const firstLoopMonthlyPrice = getMonthlyPrice(products[i].price, products[i].code, products[i].termDuration),
                    secondLoopMonthlyPrice = getMonthlyPrice(products[j].price, products[j].code, products[j].termDuration);

                if (firstLoopMonthlyPrice < secondLoopMonthlyPrice && normalizedProducts[i]) {
                    normalizedProducts[i].savingsValue = 100 - Math.round(100 * (firstLoopMonthlyPrice / secondLoopMonthlyPrice));
                    normalizedProducts[i].monthlyVersionPrice = secondLoopMonthlyPrice;
                } else if (firstLoopMonthlyPrice > secondLoopMonthlyPrice && normalizedProducts[j]) {
                    normalizedProducts[j].savingsValue = 100 - Math.round(100 * (secondLoopMonthlyPrice / firstLoopMonthlyPrice));
                    normalizedProducts[j].monthlyVersionPrice = firstLoopMonthlyPrice;

                    if (products[j].hasOwnProperty('campaign') && getCampaignDiscountPercent(products[j].campaign, products[j].price) > 0) {
                        normalizedProducts[i].hasAnnualCampaignPrice = true;
                    }
                }
            }
        }
    }

    for (let k = 0; k < products.length; k++) {
        if(products[k].hasOwnProperty('currentPlan') && products[k].currentPlan) {
            const currentProduct = CurrentProduct.getInstance();
            currentProduct.setHasCurrentProduct(true);
            currentProduct.setCurrentProduct(new Product(products[k]));
        }
    }

    Products.getInstance().setProducts(normalizedProducts);
    return normalizedProducts;
};

export const getProductsDataFromCache = (tier, trial, isNewPlans, promoId, noCache) => {
    let productsData = null;
    if (typeof noCache !== 'undefined' && noCache === true) {
        return productsData;
    }
    if (getCachedProductPlans(isNewPlans, promoId, trial) === true) {
        let cacheKey = getProductOptionsCacheKey(tier);
        productsData = DataStore.getInstance().get(cacheKey);
    }

    return productsData;
};
export const getCachedProductPlans = (isNewPlans, promoId, trial) => {
    let useCache = true;

    if((typeof isNewPlans !== "undefined" && isNewPlans) ||
        (typeof promoId !== "undefined" && promoId !== "") ||
        (typeof trial !== "undefined" && trial !== "")
    ) {
        useCache = false;
    }

    return useCache;
};

export const getProductOptionsCacheKey = (tier) => {
    if (tier === PLAN_SELECTED_TIER_EDUCATION) {
        return LOCAL_STORAGE_OPTIONS_KEY_EDUCATION;
    }
    if (tier === PLAN_SELECTED_TIER_BUSINESS) {
        return LOCAL_STORAGE_OPTIONS_KEY_BUSINESS;
    }
    return LOCAL_STORAGE_OPTIONS_KEY_PERSONAL;
};

export const isBusinessTrial = () => {
    return CurrentProduct.getInstance().getCurrentProduct().isBusiness() && isTrial();
};

export const isBusinessTrialExpired = () => {
    return isBusinessTrial() && getIsExpiredTrial()
};
