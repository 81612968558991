import Product from "./Product";

class Products {
    static instance;
    products = [];
    hasInitProducts= false;

    static getInstance = () => {
        if(!Products.instance) {
            Products.instance = new Products()
        }

        return Products.instance;
    };

    getProductByCode = (code) => {
        if(this.hasProducts()) {
            return this.products.filter(function (product) {
                return product.getCode() === code;
            })[0];
        }

        return new Product();
    };

    hasProducts = () => {
        return this.hasInitProducts;
    };

    setProducts = (products) => {
        let updatedProducts = [];
        /* istanbul ignore else */
        if (products.length) {
            // checking if there is a campaignDiscount active on any product
            let campaignDiscountPercentFound = false;
            for (let i = 0; i < products.length; i++) {
                if (products[i].campaignDiscountPercent !== 0) {
                    campaignDiscountPercentFound = true;
                    break;
                }
            }
            for (let i = 0; i < products.length; i++) {
                if (campaignDiscountPercentFound) {
                    // we have an active campaign, we don't want to show other discounts for this product
                    products[i].campaignDiscountPercentFound = campaignDiscountPercentFound;
                }
                updatedProducts.push(new Product(products[i]));
            }

            if (updatedProducts.length) {
                this.hasInitProducts = true;
                this.products = updatedProducts;
            }
        }
    };

    getProducts = () => {
        return this.products;
    };

    clearProducts = () => {
        this.products = []
    }
}

export default Products