export const LOCAL_STORAGE_APP_KEY = 'appData';
export const LOCAL_STORAGE_SELECTED_TIER_KEY = 'userSelectedTier';
export const LOCAL_STORAGE_PRODUCTS_KEY = 'products';
export const LOCAL_APP_VERSION = 'appVersion';
export const LOCAL_STORAGE_OPTIONS_KEY_PERSONAL = 'productOptionsPersonal';
export const LOCAL_STORAGE_OPTIONS_KEY_BUSINESS = 'productOptionsBusiness';
export const LOCAL_STORAGE_OPTIONS_KEY_EDUCATION = 'productOptionsEducation';
export const LOCAL_STORAGE_INSTANCE_INFO_KEY = 'instanceInfo';
export const LOCAL_STORAGE_TRACK_DATA = 'trackCheckoutData';
export const LOCAL_STORAGE_PAYPAL_DATA = 'paypalData';
export const LOCAL_BRANCH_NAME = 'branchName';
export const LOCAL_STORAGE_USER_INVITE_INFO = 'userInviteInfo';

export const LS_AB_MIXPANEL_SENT = 'ab_mixpanel';

export const LS_REGISTERED_PURCHASE_AMOUNT = 'registeredPurchaseAmount';
