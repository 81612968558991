import {combineReducers} from "redux";

const reducers = combineReducers({
    app: require('./InitAppReducer').reducer,
    user: require('./UserReducer').reducer,
    payment: require('./PaymentReducer').reducer,
    activation: require('./ActivationReducer').reducer,
    products: require('./ProductsReducer').reducer,
});

export default reducers;