import ProductDescriptionTooltip from "./ProductDescriptionTooltip";

class ProductDescription {
    text = "";
    icon = "";
    tooltip = new ProductDescriptionTooltip();

    constructor(description) {
        if(typeof description !== "undefined") {
            this.text = description.hasOwnProperty("text") ? description.text : this.text;
            this.icon = description.hasOwnProperty("icon") ? description.icon : this.icon;

            if(description.hasOwnProperty("tooltip")) {
                this.tooltip.setTooltipData(description.tooltip)
            }
        }
    }

    setText = (text) => {
        this.text = text;
    };

    getText = () => {
        return this.text;
    };

    setIcon = (icon) => {
        this.icon = icon;
    };

    getIcon = () => {
        return this.icon;
    };

    setTooltip = (tooltip) => {
        this.tooltip = tooltip;
    };

    getTooltip = () => {
        return this.tooltip;
    };

}

export default ProductDescription;