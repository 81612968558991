
class ProductDescriptionTooltip {
    hasTooltip = false;
    dataFor = "";
    text = "";
    newTooltip = false;

    constructor(tooltip) {
        this.setTooltipData(tooltip);
    }

    setTooltipData = (tooltip) => {
        if(typeof tooltip !== "undefined") {
            this.setHasTooltip(true);
            this.text = tooltip.hasOwnProperty("text") ? tooltip.text : this.text;
            this.dataFor = tooltip.hasOwnProperty("dataFor") ? tooltip.dataFor : this.dataFor;
            this.newTooltip = tooltip.hasOwnProperty("newTooltip") ? tooltip.newTooltip : this.newTooltip;
        }
    };

    setHasTooltip = (hasTooltip) => {
        this.hasTooltip = hasTooltip;
    };

    getHasTooltip = () => {
        return this.hasTooltip;
    };

    setText = (text) => {
        this.text = text;
    };

    getText = () => {
        return this.text;
    };

    setDataFor = (dataFor) => {
        this.dataFor = dataFor;
    };

    getDataFor = () => {
        return this.dataFor;
    };

    setNewTooltip = (newTooltip) => {
        this.newTooltip = newTooltip;
    };

    getNewTooltip = () => {
        return this.newTooltip;
    };

}

export default ProductDescriptionTooltip;