import ActiveCampaign from "./ActiveCampaign";
import {ACTIVE_PROMO_CAMPAIGN} from "../constants/AppGlobal";
import {getTargetedPricingCampaign} from "../helpers/AppHelper";

class ActiveCampaigns {
    static instance;

    hasCampaigns = false;
    campaigns = [];
    activeSiteWideCampaignCoupons = [];

    static getInstance = () => {
        if(!ActiveCampaigns.instance) {
            ActiveCampaigns.instance = new ActiveCampaigns()
        }

        return ActiveCampaigns.instance;
    };

    setCampaigns = (campaigns) => {
        if(this.campaigns.length > 0) {
            this.campaigns = [];
        }

        if(campaigns.length) {
            this.hasCampaigns = true;
            for(let i = 0 ; i < campaigns.length ; i++) {
                this.campaigns.push(new ActiveCampaign(campaigns[i]));
            }
        }
    };

    getCampaigns = () => {
        return this.campaigns;
    };

    getHasCampaigns = () => {
        return this.hasCampaigns;
    };

    getCampaignBannerByLocation = (bannerLocation, checkOnMatchingProducts, purchasableProducts,  targetedPricingCampaign) => {
        if(!this.hasCampaigns) {
            return null;
        }
        let banner = null;
        this.campaigns.forEach((campaign) => {
            if(campaign.getTargeted() && targetedPricingCampaign !== campaign.getCoupon()) {
                return null
            }

            if (
                campaign.getValidTo() < new Date().getTime() || campaign.getValidFrom() > new Date().getTime() || !campaign.getBanners()) {
                return null;
            }

            const banners = campaign.getBanners(),
                candidates = [];

            for (let i = 0; i < banners.length; i++) {
                if (banners[i].getLocation() === bannerLocation) {
                    candidates.push(banners[i]);
                }
            }

            if (candidates.length > 0 && checkOnMatchingProducts && purchasableProducts && purchasableProducts !== "") {
                let products = purchasableProducts.split(",");

                for (let i = 0; i < products.length; i++) {
                    for (let j = 0; j < campaign.getProducts().length; j++) {
                        if (campaign.getProducts()[j] === products[i]) {
                            let candidatesBanner = candidates[Math.floor(Math.random() * candidates.length)];
                            /* istanbul ignore else */
                            if(candidatesBanner) {
                                banner = candidatesBanner;
                            }
                            break;
                        }
                    }
                }
            } else {
                let candidatesBanner = candidates[Math.floor(Math.random() * candidates.length)];
                /* istanbul ignore else */
                if(candidatesBanner) {
                    banner = candidatesBanner;
                }
            }
        });

        return banner;
    };

    getHasActiveSummerCampaign = () => {
        if(!this.hasCampaigns) {
            return false;
        }
        let hasActiveCampaign = false;

        this.campaigns.forEach((campaign) => {
            if (campaign.getCoupon() === "25offsummer2021" && campaign.getValidTo() > new Date().getTime() && campaign.getValidFrom() < new Date().getTime()) {
                hasActiveCampaign = true;
            }
        });

        return hasActiveCampaign;
    }

    getHasActiveTeacherCampaign = () => {
        if(!this.hasCampaigns) {
            return false;
        }
        let hasActiveCampaign = false;

        this.campaigns.forEach((campaign) => {
            if (campaign.getCoupon() === "teacherplanupgrade40" && campaign.getValidTo() > new Date().getTime() && campaign.getValidFrom() < new Date().getTime()) {
                hasActiveCampaign = true;
            }
        });

        return hasActiveCampaign;
    }

    getHasActivePromoCampaign = () => {
        if(!this.hasCampaigns) {
            return false;
        }
        let hasActiveCampaign = false;

        this.campaigns.forEach((campaign) => {
            if (campaign.getTargeted() === true) {
                // this is a targeted campaign
                if (ACTIVE_PROMO_CAMPAIGN.includes(campaign.getCoupon()) && campaign.getCoupon() === getTargetedPricingCampaign()  && campaign.getValidTo() > new Date().getTime() && campaign.getValidFrom() < new Date().getTime()) {
                    hasActiveCampaign = true;
                }
            } else {
                // those are side wide campaigns, no coupon needed
                if (ACTIVE_PROMO_CAMPAIGN.includes(campaign.getCoupon())  && campaign.getValidTo() > new Date().getTime() && campaign.getValidFrom() < new Date().getTime()) {
                    hasActiveCampaign = true;
                    this.updateActiveSiteWideCampaignCoupons(campaign.getCoupon());
                }
            }
        });

        return hasActiveCampaign;
    }
    hasActiveSiteWideCampaign = () => {
        return this.getActiveSiteWideCampaignCoupons().length > 0;
    }
    updateActiveSiteWideCampaignCoupons = (coupon) => {
        if (!this.activeSiteWideCampaignCoupons.includes(coupon)) {
            this.activeSiteWideCampaignCoupons.push(coupon);
        }
    }
    getActiveSiteWideCampaignCoupons = () => {
        return this.activeSiteWideCampaignCoupons;
    }
}

export default ActiveCampaigns