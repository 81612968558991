import React, {Component, Fragment} from "react";

import './NewPassword.scss';

import ApplicationLoader from "../../components/ui/loader/ApplicationLoader";
import FooterMinView from "../sign-in/signInComponents/FooterMinView";
import LoginToolbarView from "../sign-in/signInComponents/LoginToolbarView";

import Recaptcha from 'react-google-invisible-recaptcha';
import {getRecaptchaSiteKey} from "../../helpers/InitAppHelper";

import axios from "axios";
import URLHelper from "../../helpers/URLHelper";
import {trackError} from "../../services/track/ErrorTracker";
import {getAxiosConfig} from "../../helpers/GeneralHelper";

class NewPassword extends Component {
    constructor(props) {
        super(props);

        this.recaptchaRef = React.createRef();

        this.state = {
            showLoader: false,
            pageTitle: '',
            email: '',
            password: '',
            confirmPassword: '',
            vc: 'false',
            err1: false,
            err2: false,
            newPassInfo: '',
            campaignUser: true,
            error: '',
            submitButtonText: 'Change my password',
            recaptchaResponse: ''
        }
    }

    componentDidMount() {
        document.title = "Reset Password - WeVideo";
        const vc = URLHelper.getUrlParamValue(this.props, 'vc');
        if (vc !== null) {
            this.setState({vc: vc});
        }

        let email = URLHelper.getUrlParamValue(this.props, 'email');
        if (email !== null) {
            let campaignUser = false;
            if (window.location.hash !== "" && window.location.hash.indexOf("campaign") !== -1) {
                /* Ugh, could be a campaign email and they contain hashes */
                email += window.location.hash;
                campaignUser = true;
            }
            this.setState({email: email, campaignUser: campaignUser});
        }
    }

    submitRecaptchaResetPassForm = () => {
        this.recaptchaRef.current.execute();
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        return false;
    }

    handleShowLoader = (show) => {
        this.setState({showLoader: show});
    }

    handleSubmit = () => {
        let axiosConfig = getAxiosConfig();
        axiosConfig.headers["Cache-Control"] = "no-cache";
        axiosConfig.headers["Pragma"] = "no-cache";
        axiosConfig.headers["Expires"] = "0";
        axiosConfig.cache = false;

        let newPassData = {
            email: this.state.email,
            newPassword: this.state.password,
            confirmNewPassword: this.state.confirmPassword,
            vc: this.state.vc,
            recaptchaResponse: this.state.recaptchaResponse
        };
        let view = this;
        axios.post('/api/3/users/changepassword', newPassData, axiosConfig)
            .then(function (response) {
                if (response.hasOwnProperty("data")) {
                    let resp = response.data;
                    if (resp.success) {
                        if (view.campaignUser) {
                            view.setState({newPassInfo: 'Success! Your password has been changed'});
                        } else {
                            let count = 5;
                            let countdown = setInterval(function () {
                                view.setState({newPassInfo: 'Success!\nYou will be redirected to login page in ' + count + ' seconds'});
                                if (count === 0) {
                                    clearInterval(countdown);
                                    window.location = "//" + window.location.hostname + "/sign-in";
                                }
                                count--;
                            }, 1000);
                        }
                    } else {
                        view.setState({submitButtonText: 'RESET'});
                        if (resp.hasOwnProperty("failedValidations") && resp.failedValidations.length > 0) {
                            for (let i = 0; i < resp.failedValidations.length; i++) {
                                if (resp.failedValidations[i] === 'emptyPasswords') {
                                    view.setState({
                                        err1: "You can't leave this empty.",
                                        err2: "You can't leave this empty."
                                    });
                                } else if (resp.failedValidations[i] === 'PasswordTooShort') {
                                    view.setState({
                                        err1: "Short passwords are easy to guess. Try one with at least 6 characters.",
                                        err2: "Short passwords are easy to guess. Try one with at least 6 characters."
                                    });
                                } else if (resp.failedValidations[i] === 'PasswordsNotMatch') {
                                    view.setState({newPassInfo: ' Passwords do not match '});
                                    document.getElementById("message").addClass('error');
                                } else {
                                    //If there is any other error case, but typically this should never happen
                                    view.setState({error: 'There was a problem sending your new password. Please try again. If the problem persists, please contact WeVideo support.'});
                                }
                            }
                        } else {

                            //Password validation passes but user has already resetted his password once
                            view.setState({error: 'This link has expired.'});
                        }
                    }
                }
                view.handleShowLoader(false);
            })
            .catch(function (error) {
                trackError(error);
                trackError("RESET PASSWORD ERROR");
                //Connection problem, server error etc.
                view.setState({
                    submitButtonText: 'RESET',
                    error: 'There was a problem sending your new password. Please try again. If the problem persists, please contact WeVideo support.'
                });
                view.handleShowLoader(false);
            });
    }

    recaptchaResolved = () => {
        const token = this.recaptchaRef.current.getResponse();
        this.handleShowLoader(true);
        this.recaptchaRef.current.reset();
        this.setState({recaptchaResponse: token});
        this.handleSubmit();

    }

    submitRecaptchaNewPass = () => {
        this.recaptchaRef.current.execute();
    }

    handlePassChange = (e) => {
        this.setState({password: e.target.value});
        if (e.target.value === '' || e.target.value.length < 6) {
            this.setState({err1: true});
        } else if (e.target.value === this.state.confirmPassword) {
            this.setState({err1: false, err2: false});
        } else {
            this.setState({err1: false});
        }
    }

    handleConfirmPassChange = (e) => {
        this.setState({confirmPassword: e.target.value});
        if (e.target.value === '' || e.target.value.length < 6) {
            this.setState({err2: true});
        } else if (e.target.value === this.state.confirmPassword) {
            this.setState({err1: false, err2: false});
        } else {
            this.setState({err2: false});
        }
    }


    render() {
        return (
            <Fragment>
                <ApplicationLoader showLoader={this.state.showLoader}/>
                <div className="new-password-page-wrapper">
                    <LoginToolbarView/>
                    <div className="new-password-card">
                        <div className="new-password-inner">
                            <div className="card-title">Reset password</div>
                            <div className="card-text">
                                <div className="card-subtitle">Create a new password for your account</div>
                                <div className="link"><a href="/sign-in">{this.state.email} - <span>Not you?</span></a>
                                </div>

                                <form id="resetPasswordForm" action="" method="post" autoComplete="off" noValidate=""
                                      onSubmit={this.handleFormSubmit}>
                                    <Recaptcha
                                        ref={this.recaptchaRef}
                                        sitekey={getRecaptchaSiteKey()}
                                        onResolved={this.recaptchaResolved}
                                    />
                                    <div className="inputRow">
                                        <input id="password" type="password" name="newPassword"
                                               placeholder="Enter new password" required="" autoComplete="off"
                                               maxLength="50" value={this.state.password}
                                               onChange={(e) => {
                                                   this.handlePassChange(e)
                                               }}/>
                                        <div id="newpass-info" className="errorMsg1"
                                             hidden={this.state.err1 === false}>
                                            {this.state.password === '' ? "You can't leave this empty." :
                                                this.state.password !== '' && this.state.password.length < 6 ? 'Short passwords are easy to guess. Try one with at least 6 characters.' : ''
                                            }</div>
                                    </div>
                                    <div className="inputRow">
                                        <input id="confirm_password" type="password" name="confirmNewPassword"
                                               placeholder="Confirm password" required="" autoComplete="off"
                                               maxLength="50"
                                               value={this.state.confirmPassword}
                                               onChange={(e) => {
                                                   this.handleConfirmPassChange(e)
                                               }}/>
                                        <div id="confirm-newpass-info" className="errorMsg2"
                                             hidden={this.state.err2 === false}>
                                            {this.state.confirmPassword === '' ? "You can't leave this empty." :
                                                this.state.confirmPassword !== '' && this.state.confirmPassword.length < 6 ? 'Short passwords are easy to guess. Try one with at least 6 characters.' : ''
                                            }
                                        </div>
                                    </div>

                                    <div className="messageOuter">
                                        <div id="message"
                                             className={"message " + (this.state.password !== this.state.confirmPassword ? 'error' : '')}
                                             hidden={this.state.err1 || this.state.err2}>
                                                {this.state.password !== this.state.confirmPassword ? 'Passwords do not match' :
                                                    this.state.password === this.state.confirmPassword && this.state.password !== '' ? this.state.newPassInfo === '' ? 'OK' : this.state.newPassInfo : ""}
                                        </div>
                                    </div>

                                    <div className="submitRow">
                                        <input type="submit"
                                               value={this.state.submitButtonText}
                                               name="Reset"
                                               className="submitBtn"
                                               id="submit"
                                               data-trackingid="Reset-Password-Reset"
                                               disabled={this.state.password !== this.state.confirmPassword ||
                                                   this.state.err1 === true ||
                                                   this.state.err2 === true ||
                                                   this.state.password === ''}
                                               hidden={this.state.newPassInfo !== ''}
                                               onClick={this.submitRecaptchaNewPass}
                                        />
                                    </div>
                                    <div id="feedback" className='feedback error'
                                         hidden={this.state.error === ''}>
                                        {this.state.error}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <FooterMinView/>
                </div>
            </Fragment>
        )
    }
}

export default NewPassword;
