import React from "react"
import { Switch } from "react-router-dom"
import {APP_BROWSER_ROUTES, APP_ROUTES} from "../constants/AppGlobal";

// Async Component
import asyncComponent from "../components/asyncComponent/AsyncComponent";

// Route Type
import DefaultRoute from "./types/DefaultRoute";

// Layouts
import DefaultLayout from "../layouts/DefaultLayout";
import NewPassword from "../pages/newPassword/NewPassword";

// Pages
const AsyncHomePage = asyncComponent( () => import("../pages/home/HomePage"));
const AsyncPlansPage = asyncComponent( () => import("../pages/plans/PlansPageVariation"));

const AsyncCheckoutPage = asyncComponent( () => import("../pages/checkout/CheckoutPage"));
const AsyncSegmentController = asyncComponent( () => import("../pages/segment/SegmentController"));
const AsyncRCELandingPage = asyncComponent( () => import("../pages/RCELandingPage"));
const AsyncInvitePage = asyncComponent( () => import("../pages/InvitePage"));
const AsyncSignInPage = asyncComponent( () => import("../pages/sign-in/SignInPage"));
const AsyncSignUpPage = asyncComponent( () => import("../pages/sign-in/SignUpPage"));

const AsyncTemplateRedirect = asyncComponent( () => import("../pages/TemplateRedirect"));
const AsyncExpiredAccount = asyncComponent( () => import("../pages/ExpiredAccount"));
const AsyncSegmentationInfoRedirect = asyncComponent( () => import("../pages/SegmentationInfoRedirect"));

const appRoutesBrowser = ({childProps}) => {

    return(
        <Switch>

            <DefaultRoute
                path={APP_ROUTES.PLANS_SIGN_UP}
                exact
                component={AsyncPlansPage}
                props={childProps}
                layoutProps={{
                    pageName: "Pricing Page",
                    hasHeader: false
                }}
                layout={DefaultLayout}
            />

            <DefaultRoute
                path={APP_ROUTES.PLANS_PAGE}
                exact
                component={AsyncPlansPage}
                props={childProps}
                layoutProps={{
                    pageName: "Plans Page",
                    hasHeader: false
                }}
                layout={DefaultLayout}
            />

            <DefaultRoute
                path={APP_ROUTES.HOMEPAGE}
                exact
                component={AsyncHomePage}
                layout={DefaultLayout}
                props={childProps}
                layoutProps={{
                    pageName: "Home Page"
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.SEGMENT_WHAT_FOR_STEP1}
                exact
                component={AsyncSegmentController}
                layout={DefaultLayout}
                props={{...childProps, currentStep: 1}}
                layoutProps={{
                    pageName: "Segment What For Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.SEGMENT_WHAT_ROLE_STEP2}
                exact
                component={AsyncSegmentController}
                layout={DefaultLayout}
                props={{...childProps, currentStep: 2}}
                layoutProps={{
                    pageName: "Segment What Role Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.SEGMENT_ABOUT_YOU_STEP3}
                exact
                component={AsyncSegmentController}
                layout={DefaultLayout}
                props={{...childProps, currentStep: 3}}
                layoutProps={{
                    pageName: "Segment Who Are You Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.INVALID_EMAIL_PAGE}
                exact
                component={AsyncSegmentController}
                layout={DefaultLayout}
                props={{...childProps, currentStep: 69}}
                layoutProps={{
                    pageName: "Invalid Email Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.PLANS_PAGE_BROWSER}
                exact
                component={AsyncPlansPage}
                props={childProps}
                layoutProps={{
                    pageName: "Plans Page",
                    hasHeader: false
                }}
                layout={DefaultLayout}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.CHECKOUT_SUMMARY_PAGE}
                exact
                component={AsyncCheckoutPage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Checkout Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.RCE_LANDING}
                exact
                component={AsyncRCELandingPage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "RCE Login",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_ROUTES.INVITE_PAGE}
                exact
                component={AsyncInvitePage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Invitation page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_ROUTES.INVITE_PAGE_SLASH}
                exact
                component={AsyncInvitePage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Invitation page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_ROUTES.LOGIN_PAGE}
                exact
                component={AsyncSignInPage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Login page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_ROUTES.NEW_PASSWORD}
                exact
                component={NewPassword}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "New password page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_ROUTES.SIGN_UP_PAGE}
                exact
                component={AsyncSignUpPage}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Sign up page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.TEMPLATE_REDIRECT}
                exact
                component={AsyncTemplateRedirect}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Template Redirect Page",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.EXPIRED_ACCOUNT}
                exact
                component={AsyncExpiredAccount}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Expired account",
                    hasHeader: false
                }}
            />

            <DefaultRoute
                path={APP_BROWSER_ROUTES.SEGMENTATION_INFO_REDIRECT}
                exact
                component={AsyncSegmentationInfoRedirect}
                layout={DefaultLayout}
                props={{...childProps}}
                layoutProps={{
                    pageName: "Segmentation Info Redirect Page",
                    hasHeader: false
                }}
            />

        </Switch>
    );
};

export default appRoutesBrowser;
