class DataStore {
    static instance;
    data = {};

    static getInstance = () => {
        if(!DataStore.instance) {
            DataStore.instance = new DataStore();
        }

        return DataStore.instance;
    };

    set = (key, value) => {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        this.data[key] = value;
    };

    get = (key) => {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        if(!this.data.hasOwnProperty(key)) {
            return null;
        }

        return this.data[key];
    };

    delete = (key) => {
        if(!key) {
            throw new Error('You need to specify a key')
        }

        this.data[key] = undefined;
        try{
            delete this.data[key];
        }catch(e){}
    };

    update = (storageKey, updateKey, newValue) => {
        try {
            let object = {}, storeValue = this.get(storageKey);
            if(storeValue !== null) {
                object = storeValue;

                if (object.hasOwnProperty(updateKey)) {
                    object[updateKey] = newValue;
                } else {
                    object[updateKey] = newValue
                }
            } else {
                object[updateKey] = newValue;
            }

            this.set(storageKey, object);
        } catch (e) { }
    };
    
    updateObject = (storageKey, properties) => {
    	var obj = this.get(storageKey) || {};
    	for (var prop in properties) {
            /* istanbul ignore else */
    	    if (Object.prototype.hasOwnProperty.call(properties, prop)) {
    	       obj[prop] = properties[prop]
    	    }
    	}
    	
        this.set(storageKey, obj);
    };

    clearDataStore = () => {
        this.data = {};
    };
}

export default DataStore;