/* istanbul ignore file */
import { TrackJS } from "trackjs";
import {trackError} from "../services/track/ErrorTracker";

const TrackJSLogger = store => next => action => {
    try {
        // log every action so they appear in the TrackJS telemetry timeline
        TrackJS.console.log(action);

        return next(action)
    } catch (err) {
        // Something bad happened, lets log out the entire state so we can see it in the timeline
        /* istanbul ignore next */
        console.warn(store.getState());

        // NOTE: this assumes TrackJS was initialized previously, at app startup.
        /* istanbul ignore next */
        trackError(err);
    }
};

export default TrackJSLogger;