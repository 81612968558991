import React, {Component} from 'react';
import './ApplicationLoader.scss';

export default class ApplicationLoader extends Component {
    render() {
        if (this.props.showLoader === true) {
            return (
                <div className="applicationLoader">
                    <div className="loaderBG"/>
                </div>
            );
        } else {
            return null;
        }
    }
}