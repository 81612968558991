import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const {Types, Creators} = createActions({
    getProducts: {},
    getProductsSuccess: null,
    getProductsFailure: null,
});

export const ProductsTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
    products: [],
    loadingProducts: true,
    errorProducts: '',
});

/* Selectors */
export const ProductsSelectors = {};

export const getProductsReducer = (state) => {
    return {
        ...state,
        loadingProducts: true
    };
};
export const getProductsSuccessReducer = (state, action) => {
    const {products} = action;
    return {
        ...state,
        loadingProducts: false,
        products: products,
    };
};
export const getProductsFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        loadingProducts: false,
        errorProducts: error,
    };
};



export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PRODUCTS]: getProductsReducer,
    [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccessReducer,
    [Types.GET_PRODUCTS_FAILURE]: getProductsFailureReducer,

});
