import React, {Component, Fragment} from 'react'
import PropTypes from "prop-types";

import './../assets/redesignedCss/index.scss';

import Tester from "../components/tester/Tester";
import {withRouter} from "react-router-dom";
import {hasGtmEnabled, isAuth, isInHub} from "../helpers/AppHelper";
import GeneralTrack from "../services/track/GeneralTrack";
import CookieScripts from "../components/cookieScripts/CookieScripts";
import {connect} from "react-redux";

class DefaultLayout extends Component {
    hubPageViewTracked = false;
    srcLoaded = false;
    canTrack = true;

    constructor(props) {
        super(props);

        this.state = {
            appWasLoaded: false,
            appLoadError: null,
            scriptsLoaded: false
        }

        if (!hasGtmEnabled()) {
            this.canTrack = false;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.appWasLoaded !== prevState.appWasLoaded) {
            return ({appWasLoaded: nextProps.appWasLoaded})
        }
        if (nextProps.appLoadError !== prevState.appLoadError) {
            return ({appLoadError: nextProps.appLoadError})
        }

        return null;
    }

    componentDidMount() {
        this.trackDataLayerSession();
        GeneralTrack.getInstance().processSessionStorageEvents();

        this.props.history.listen((location, action) => {
            this.trackDataLayerSession();
            GeneralTrack.getInstance().processSessionStorageEvents();
        });
        if (this.shouldWeLoadScripts() === true && this.canTrack === true) {
            this.insertScriptInHead();
        }
    };

    trackDataLayerSession = () => {
        if(isInHub() && isAuth() && this.canTrack === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'userSessionLoaded': 'true'});
            window.dataLayer.push({"event": "UserSessionAndCookiePreferencesLoaded"});
        }
    };

    insertScriptInHead = () => {
        if (this.canTrack === false) {
            return;
        }
        let clearBitFound = false, shareASaleFound = false;
        for (let i = 0; i < document.head.children.length; i++) {
            let elem = document.head.children[i];
            if (elem.tagName === "SCRIPT" && elem.src) {
                if (elem.src.indexOf("tag.clearbitscripts.com") !== -1) {
                    clearBitFound = true;
                }
                if (elem.src.indexOf("www.dwin1.com") !== -1) {
                    shareASaleFound = true;
                }
            }
        }
        if (clearBitFound === false) {
            const script = document.createElement("script");
            script.src = "https://tag.clearbitscripts.com/v1/pk_dcf4eda4dd28b11bba60c0bce2804cdb/tags.js";
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
        if (shareASaleFound === false) {
            const shareASaleScript = document.createElement("script");
            shareASaleScript.src = "https://www.dwin1.com/19038.js";
            shareASaleScript.async = true;
            shareASaleScript.defer = true;
            document.head.appendChild(shareASaleScript);
        }

        this.srcLoaded = true;
        this.setState({scriptsLoaded: true});
    };
    shouldWeLoadScripts = () => {
        return (
            this.state.appWasLoaded === true &&
            this.state.scriptsLoaded === false &&
            this.srcLoaded === false
        );
    };
    render() {
        return (
            <Fragment>
                {
                    this.props.hasHeader ?
                        <Tester companyName="WeVideo" pageName={this.props.pageName} />
                        : null
                }

                {this.props.children}

                {
                    this.canTrack === true ? (
                        <CookieScripts />
                    ) : null
                }
            </Fragment>
        );
    }
}

DefaultLayout.defaultProps = {
    pageName: '',
    hasHeader: true,
};

DefaultLayout.propTypes = {
    pageName: PropTypes.string,
    hasHeader: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        appWasLoaded: state.app.appLoaded,
        appLoadError: state.app.error
    }
};

export default withRouter(connect(mapStateToProps, null)(DefaultLayout));
