import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const {Types, Creators} = createActions({
    getUser: {},
    getUserSuccess: null,
    getUserFailure: null,

    getUserContext: {},
    getUserContextSuccess: null,

    getUserProductOptions: {},
    getUserProductOptionsSuccess: null,
    clearUserProductOptions: null,

    setUserAttribute: {},

    activateAccount: {},
    activateAccountSuccess: null,
    activateAccountFailure: null,

    editUserDetails: {},
    editUserDetailsSuccess: null,
    editUserDetailsFailure: null,

    deleteMembers: {},
    deleteMembersSuccess: null,
    deleteMembersFailure: null,

    verifyTeacherEmail: {},
    verifyTeacherEmailSuccess: null,
    verifyTeacherEmailFailure: null,

    getUserInviteInfo: {},
    getUserInviteInfoSuccess: null,
    getUserInviteInfoFailure: null,

    useInviteCode: {},
    useInviteCodeSuccess: null,
    useInviteCodeFailure: null,
});

export const UserTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
    user: [],
    context: null,
    options: null,
    error: null,

    activateAccountSuccess: null,
    activateAccountFailure: null,

    editUserDetailsSuccess: null,
    editUserDetailsFailure: null,

    deleteMembersSuccess: null,
    deleteMembersErrorMessage: null,

    verifyTeacherEmailSuccess: null,
    verifyTeacherEmailFailure: null,

    getUserInviteInfo: null,
    getUserInviteInfoSuccess: null,
    getUserInviteInfoFailure: null,

    useInviteCode: null,
    useInviteCodeSuccess: null,
    useInviteCodeFailure: null,
});

/* Selectors */
export const UserSelectors = {};

export const getUserReducer = (state) => {
    return {
        ...state,
    };
};
export const getUserSuccessReducer = (state, action) => {
    const { user } = action;
    return {
        ...state,
        user: user,
    }
};
export const getUserFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        error: error,
    };
};


export const getUserContextReducer = (state) => {
    return {
        ...state,
    };
};
export const getUserContextSuccessReducer = (state, action) => {
    const { context } = action;
    return {
        ...state,
        context: context,
    }
};

export const getUserProductOptionsReducer = (state) => {
    return {
        ...state,
    };
};
export const getUserProductOptionsSuccessReducer = (state, action) => {
    const { options } = action;
    return {
        ...state,
        options: options,
    }
};
export const clearUserProductOptionsReducer = (state) => {
    return {
        ...state,
        options: null,
    }
};

export const setUserAttributeReducer = (state) => {
    return {
        ...state,
    };
};

export const activateAccountReducer = (state) => {
    return {
        ...state,
    };
};
export const activateAccountSuccessReducer = (state, action) => {
    const {activateAccountSuccess} = action;
    return {
        ...state,
        activateAccountSuccess: activateAccountSuccess,
    }
};
export const activateAccountFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        activateAccountSuccess: false,
        activateAccountMessage: error,
    };
};

export const editUserDetailsReducer = (state) => {
    return {
        ...state,
    };
};
export const editUserDetailsSuccessReducer = (state, action) => {
    return {
        ...state,
        editUserDetailsSuccess: true,
    }
};
export const editUserDetailsFailureReducer = (state, action) => {
    return {
        ...state,
        editUserDetailsSuccess: false,
    };
};

export const deleteMembersReducer = (state) => {
    return {
        ...state,
    };
};
export const deleteMembersSuccessReducer = (state, action) => {
    return {
        ...state,
        deleteMembersSuccess: true,
    }
};
export const deleteMembersFailureReducer = (state, action) => {
    const {deleteMembersErrorMessage} = action;
    return {
        ...state,
        deleteMembersSuccess: false,
        deleteMembersErrorMessage: deleteMembersErrorMessage
    };
};

export const verifyTeacherEmailReducer = (state) => {
    return {
        ...state,
    };
};
export const verifyTeacherEmailSuccessReducer = (state, action) => {
    const {verifyTeacherEmailSuccess} = action;
    return {
        ...state,
        verifyTeacherEmailSuccess: verifyTeacherEmailSuccess,
    }
};
export const verifyTeacherEmailFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        verifyTeacherEmailSuccess: false,
        verifyTeacherEmailMessage: error,
    };
};

export const getUserInviteInfoReducer = (state) => {
    return {
        ...state,
    };
};
export const getUserInviteInfoSuccessReducer = (state, action) => {
    const { userInviteInfo } = action;
    return {
        ...state,
        userInviteInfoSuccess: true,
        userInviteInfo: userInviteInfo,
    }
};
export const getUserInviteInfoFailureReducer = (state, action) => {
    const {error} = action;
    return {
        ...state,
        userInviteInfoSuccess: false,
        userInviteInfoMessage: error,
    };
};

export const useInviteCodeReducer = (state) => {
    return {
        ...state,
    };
};
export const useInviteCodeSuccessReducer = (state, action) => {
    const { useInviteCodeSuccess, useInviteCodeMessage } = action;
    return {
        ...state,
        useInviteCodeSuccess: useInviteCodeSuccess,
        useInviteCodeMessage: useInviteCodeMessage,
    }
};
export const useInviteCodeFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        useInviteCodeSuccess: false,
        useInviteCodeMessage: error,
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_USER]: getUserReducer,
    [Types.GET_USER_SUCCESS]: getUserSuccessReducer,
    [Types.GET_USER_FAILURE]: getUserFailureReducer,
    [Types.GET_USER_CONTEXT]: getUserContextReducer,
    [Types.GET_USER_CONTEXT_SUCCESS]: getUserContextSuccessReducer,
    [Types.GET_USER_PRODUCT_OPTIONS]: getUserProductOptionsReducer,
    [Types.GET_USER_PRODUCT_OPTIONS_SUCCESS]: getUserProductOptionsSuccessReducer,
    [Types.CLEAR_USER_PRODUCT_OPTIONS]: clearUserProductOptionsReducer,

    [Types.SET_USER_ATTRIBUTE]: setUserAttributeReducer,

    [Types.ACTIVATE_ACCOUNT]: activateAccountReducer,
    [Types.ACTIVATE_ACCOUNT_SUCCESS]: activateAccountSuccessReducer,
    [Types.ACTIVATE_ACCOUNT_FAILURE]: activateAccountFailureReducer,

    [Types.EDIT_USER_DETAILS]: editUserDetailsReducer,
    [Types.EDIT_USER_DETAILS_SUCCESS]: editUserDetailsSuccessReducer,
    [Types.EDIT_USER_DETAILS_FAILURE]: editUserDetailsFailureReducer,

    [Types.DELETE_MEMBERS]: deleteMembersReducer,
    [Types.DELETE_MEMBERS_SUCCESS]: deleteMembersSuccessReducer,
    [Types.DELETE_MEMBERS_FAILURE]: deleteMembersFailureReducer,

    [Types.VERIFY_TEACHER_EMAIL]: verifyTeacherEmailReducer,
    [Types.VERIFY_TEACHER_EMAIL_SUCCESS]: verifyTeacherEmailSuccessReducer,
    [Types.VERIFY_TEACHER_EMAIL_FAILURE]: verifyTeacherEmailFailureReducer,

    [Types.GET_USER_INVITE_INFO]: getUserInviteInfoReducer,
    [Types.GET_USER_INVITE_INFO_SUCCESS]: getUserInviteInfoSuccessReducer,
    [Types.GET_USER_INVITE_INFO_FAILURE]: getUserInviteInfoFailureReducer,

    [Types.USE_INVITE_CODE]: useInviteCodeReducer,
    [Types.USE_INVITE_CODE_SUCCESS]: useInviteCodeSuccessReducer,
    [Types.USE_INVITE_CODE_FAILURE]: useInviteCodeFailureReducer,
});