import { call, put } from "redux-saga/effects";
import {ProductsTypes} from "../redux/ProductsReducer";
import {
    getProductOptionsCacheKey, getProductsDataFromCache,
    normalizeProducts,
    setCurrentProduct,
} from "../helpers/ProductsHelper";
import * as InitAppHelper from "../helpers/InitAppHelper";
import {LOCAL_STORAGE_APP_KEY} from "../constants/LocalStorage";
import DataStore from "../helpers/DataStore";
import {trackError} from "../services/track/ErrorTracker";

const getProducts = function* (api, action) {
    let {tier, isNewPlans, promoId, trial} = action;
    isNewPlans = typeof isNewPlans !== "undefined" ? isNewPlans : false;
    promoId = typeof promoId !== "undefined" ? promoId : "";
    trial = typeof trial !== "undefined" ? trial : "";

    if(typeof window.products !== "undefined") {
        // for unit test's output uncomment and fix with the following output from the console log
        // console.log(JSON.stringify(normalizeProducts(window.products[tier])));

        yield put({
            type: ProductsTypes.GET_PRODUCTS_SUCCESS,
            products: normalizeProducts(window.products[tier]),
        });

    } else {
        try {
            let response = {};
            let productsData = getProductsDataFromCache(tier, trial, isNewPlans, promoId);

            if (productsData !== null && productsData.hasOwnProperty('products')) {
                response = {
                    status: 200,
                    data: productsData
                };
            }
            if (productsData === null) {
                response = yield call(api.getProducts, tier, isNewPlans, promoId, trial);
            }

            if (response.status === 200 && response.data.hasOwnProperty('products')) {
                const productsOptions = response.data;

                let cacheKey = getProductOptionsCacheKey(tier);
                DataStore.getInstance().set(cacheKey, productsOptions);

                /* istanbul ignore else */
                if(productsOptions.hasOwnProperty('priceTestingGroupValue')) {
                    DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceTestingGroup', InitAppHelper.setPriceTestingGroup(productsOptions));
                    DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceTestingGroupValue', InitAppHelper.setPriceTestingGroupValue(productsOptions));
                    DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'priceCampaignKey', InitAppHelper.setPriceCampaignKey(productsOptions));
                }

                setCurrentProduct(productsOptions);

                // for unit test's output uncomment and fix with the following output from the console log
                // console.log(JSON.stringify(normalizeProducts(productsOptions.products)));

                yield put({
                    type: ProductsTypes.GET_PRODUCTS_SUCCESS,
                    products: normalizeProducts(productsOptions.products),
                });
            } else {
                yield put({type: ProductsTypes.GET_PRODUCTS_FAILURE, error: "Fail to get Products"});
            }
        } catch (error) {
            yield put({type: ProductsTypes.GET_PRODUCTS_FAILURE, error: error.message});
            trackError(error);
        }
    }
};

export {
    getProducts,
}