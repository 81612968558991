import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import './css/tester.scss';
import {isAuth, getUserUserName} from "../../helpers/AppHelper";
import {APP_EXTERNAL_URLS, APP_ROUTES} from "../../constants/AppGlobal";
import URLHelper from "../../helpers/URLHelper";
import {isLocalHost} from "../../helpers/GeneralHelper";

class Tester extends React.Component {
    constructor(props) {
        super(props);
        let redirectUrl = APP_ROUTES.PLANS_PAGE;
        if (isLocalHost()) {
            redirectUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + redirectUrl;
        }
        this.state = {
            isLoggedIn: props.isLoggedIn,
            userName: props.userName,
            redirectURL: redirectUrl
        }
    }
    componentDidMount() {
        this.setState({
            isLoggedIn: isAuth(),
            userName: getUserUserName()
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.userName !== getUserUserName() || prevState.isLoggedIn !== isAuth()) {
            this.setState({
                isLoggedIn: isAuth(),
                userName: getUserUserName()
            });
        }
    }
    handleMenuLinkClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.hasOwnProperty('target') && e.target instanceof HTMLAnchorElement) {
            let url = e.target.getAttribute('href');
            URLHelper.goTo(url);
        }
    };
    render() {
        return (
            <div className="header">
                <div className="proxyLinks">
                    <ul>
                        <ol key="home"><a onClick={this.handleMenuLinkClick} href={APP_ROUTES.HOMEPAGE}>Homepage</a></ol>
                        {isAuth() === true ? (
                            <ol key="plans"><a onClick={this.handleMenuLinkClick} href={APP_ROUTES.PLANS_PAGE}>Plans</a></ol>
                        ) : (
                            <ol key="plans"><a onClick={this.handleMenuLinkClick} href={APP_EXTERNAL_URLS.NOT_LOGGED_PLANS_PAGE}>Pricing</a></ol>
                        )}
                        <ol key="invite"><a onClick={this.handleMenuLinkClick} href={APP_ROUTES.INVITE_PAGE}>Invite</a></ol>

                        <ol key="new-password"><a onClick={this.handleMenuLinkClick} href={APP_ROUTES.NEW_PASSWORD}>New Password</a></ol>
                        {this.state.isLoggedIn ? (
                            <ol key="logout"><a href={APP_EXTERNAL_URLS.LOG_OUT_URL}>Logout</a></ol>
                        ) : (
                            <Fragment>
                                <ol key="auth-personal">
                                    <a onClick={this.handleMenuLinkClick} href={APP_EXTERNAL_URLS.SIGN_UP + '?tier=personal&redirectURL=' + this.state.redirectURL}>Sign up - personal</a>
                                </ol>
                                <ol key="auth-business">
                                    <a onClick={this.handleMenuLinkClick} href={APP_EXTERNAL_URLS.SIGN_UP + '?tier=business&redirectURL=' + this.state.redirectURL}>Sign up - business</a>
                                </ol>
                                <ol key="auth-education">
                                    <a onClick={this.handleMenuLinkClick} href={APP_EXTERNAL_URLS.SIGN_UP + '?tier=education&redirectURL=' + this.state.redirectURL}>Sign up - education</a>
                                </ol>
                                <ol key="auth-sign-in">
                                    <a onClick={this.handleMenuLinkClick} href={APP_EXTERNAL_URLS.SIGN_IN + '?redirectURL=' + APP_ROUTES.NEXTGEN_DASHBOARD}>LOGIN</a>
                                </ol>
                            </Fragment>
                        )}
                        <ol key="username" className="username">{this.state.userName}</ol>
                    </ul>
                </div>
                <h2 className="testerClass">This is the very beginning of {this.props.companyName} checkout, page: {this.props.pageName}</h2>
            </div>
            )
    }
}

export default withRouter(Tester);
