import React from "react";

import './FooterMinView.scss';
import moment from "moment";

export default class FooterMinView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentYear: moment().format('YYYY'),
        }
    }
    render() {
        return (
            <div id="footerMin" className="aliC font12">
                © { this.state.currentYear } WeVideo, Inc.&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms-of-use" target="_blank" rel="noopener">Terms
                of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/privacy" target="_blank" rel="noopener">Privacy
                Policy</a>
            </div>
        )
    }
}