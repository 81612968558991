import ActiveCampaignBanner from "./ActiveCampaignBanner";

class ActiveCampaign {
    id = 0;
    name = "";
    coupon = "";
    targeted = false;
    products = [];
    validFrom = 0;
    validTo = 0;
    banners = [];

    constructor(campaign) {
        if(campaign && campaign.hasOwnProperty('id')) {
            this.id = campaign.id;
            this.name = campaign.hasOwnProperty('name') ? campaign.name : this.name;
            this.coupon = campaign.hasOwnProperty('coupon') ? campaign.coupon : this.coupon;
            this.targeted = campaign.hasOwnProperty('targeted') ? campaign.targeted : this.targeted;
            this.products = campaign.hasOwnProperty('products') ? campaign.products : this.products;
            this.validFrom = campaign.hasOwnProperty('validFrom') ? campaign.validFrom : this.validFrom;
            this.validTo = campaign.hasOwnProperty('validTo') ? campaign.validTo : this.validTo;

            if(campaign.hasOwnProperty('banners') && campaign.banners.length) {
                for(let i = 0 ; i < campaign.banners.length ; i++) {
                    this.banners.push(new ActiveCampaignBanner(campaign.banners[i]));
                }
            }
        }
    }

    setId = (id) => {
        this.id = id;
    };

    getId = () => {
        return this.id;
    };

    setName = (name) => {
        this.name = name;
    };

    getName = () => {
        return this.name;
    };

    setCoupon = (coupon) => {
        this.coupon = coupon;
    };

    getCoupon = () => {
        return this.coupon;
    };

    setTargeted = (targeted) => {
        this.targeted = targeted;
    };

    getTargeted = () => {
        return this.targeted;
    };

    setProducts = (products) => {
        this.products = products;
    };

    getProducts = () => {
        return this.products;
    };

    setValidFrom = (validFrom) => {
        this.validFrom = validFrom;
    };

    getValidFrom = () => {
        return this.validFrom;
    };

    setValidTo = (validTo) => {
        this.validTo = validTo;
    };

    getValidTo = () => {
        return this.validTo;
    };

    setBanners = (banners) => {
        this.banners = banners;
    };

    getBanners = () => {
        return this.banners;
    };
}

export default  ActiveCampaign;