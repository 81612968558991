
class CurrentProductFeatures {
    features = "";

    setFeatures = (features) => {
        this.features = features;
    };

    getFeatures = () => {
        return this.features;
    };

    hasFeatures = (feature) => {
        return this.features.split(" ").indexOf(feature) !== -1
    }
}

export default CurrentProductFeatures;