import CurrentProductFeatures from "./CurrentProductFeatures";
import Product from "./Product";
import {isProductK12, isProfessionalPlan} from "../helpers/PlansHelper";

class CurrentProduct {
    static instance;

    hasCurrentProduct= false;
    product = new Product();
    code = "";
    currentPrice = 0;
    accountBalance = 0;
    extraSeats = 0;
    prorateRatio = 0;
    periodStart = 0;
    periodEnd = 0;
    features = new CurrentProductFeatures();
    gbPerSeat = 0;
    hoursPerSeat = 0;
    fourKExportPrice = 0;
    exportPrice = 0;
    vertical = "";

    static getInstance = () => {
        if(!CurrentProduct.instance) {
            CurrentProduct.instance = new CurrentProduct()
        }

        return CurrentProduct.instance;
    };

    isEducation = () => {
        return isProductK12(this.product.getCode());
    };

    isBusiness = () => {
        return this.getCode().indexOf("bizoct") !== -1;
    };

    isProfessional = () => {
        return isProfessionalPlan(this.getCode());
    };

    setCurrentProduct = (product) => {
        this.product = product;
    };

    getCurrentProduct = () => {
        return this.product;
    };

    setHasCurrentProduct = (hasCurrentProduct) => {
        this.hasCurrentProduct = hasCurrentProduct;
    };

    getHasCurrentProduct = () => {
        return this.hasCurrentProduct;
    };

    setCode = (code) => {
        this.code = code;
    };

    getCode = () => {
        return this.code;
    };

    setCurrentPrice = (currentPrice) => {
        this.currentPrice = currentPrice;
    };

    getCurrentPrice = () => {
        return this.currentPrice;
    };

    setAccountBalance = (accountBalance) => {
        this.accountBalance = accountBalance;
    };

    getAccountBalance = () => {
        return this.accountBalance;
    };

    setExtraSeats = (extraSeats) => {
        this.extraSeats = extraSeats;
    };

    getExtraSeats = () => {
        return this.extraSeats;
    };

    setProrateRatio = (prorateRatio) => {
        this.prorateRatio = prorateRatio;
    };

    getProrateRatio = () => {
        return this.prorateRatio;
    };

    setPeriodStart = (periodStart) => {
        this.periodStart = periodStart;
    };

    getPeriodStart = () => {
        return this.periodStart;
    };

    setPeriodEnd = (periodEnd) => {
        this.periodEnd = periodEnd;
    };

    getPeriodEnd = () => {
        return this.periodEnd;
    };

    setFourKExportPrice = (fourKExportPrice) => {
        this.fourKExportPrice = Number(fourKExportPrice);
    };

    getFourKExportPrice = () => {
        return this.fourKExportPrice;
    };

    setHDExportPrice = (exportPrice) => {
        this.exportPrice = Number(exportPrice);
    };

    getHDExportPrice = () => {
        return this.exportPrice;
    };

    getExportPriceByCode = (code) => {
        if(code === 'single_export_hd') {
            return this.getHDExportPrice();
        } else if(code === 'single_export_4k') {
            return this.getFourKExportPrice();
        }

        return 0;
    };

    setFeatures = (features) => {
        this.features.setFeatures(features);
    };

    getFeatures = () => {
        return this.features;
    };

    setVertical = (vertical) => {
        this.vertical = vertical;
    };

    getVertical = () => {
        return this.vertical;
    };
}

export default CurrentProduct;
