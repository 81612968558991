import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/api/Api'
import APIFixture from '../services/api/ApiFixture'

/* TYPES */
import { InitAppTypes} from "../redux/InitAppReducer";
import { UserTypes} from "../redux/UserReducer";
import { PaymentTypes} from "../redux/PaymentReducer";
import { ActivationTypes } from "../redux/ActivationReducer";
import { ProductsTypes } from "../redux/ProductsReducer";

/* SAGAS */
import { initApp } from "./InitAppSaga";
import {
    getUser,
    getUserProductOptions,
    getUserContext,
    setUserAttribute,
    activateAccount,
    editUserDetails,
    deleteMembers,
    verifyTeacherEmail,
    getUserInviteInfo,
    useInviteCode,
} from "./UserSaga";
import {
    processFreeTrial,
    processPayment,
    sendPaymentToken,
    processPayPalPayment,
    invoice,
    orderSummary,
    cancelEssentialsSubscription,
    cancelSubscription, processAddressForm,
} from "./PaymentSaga";
import {
    verifyEmailCode,
    generateCode,
    convertToFree,
    cancelTrial, verifyCoupon, leaveInstance, applyCoupon
} from "./ActivationSaga";
import { getProducts } from "./ProductsSaga";

let api;
/* istanbul ignore else */
if(process.env.NODE_ENV === "test") {
    api = APIFixture;
} else {
    api = API.create();
}

export default function* root() {
    yield all([
        takeLatest(InitAppTypes.INIT_APP, initApp, api),

        takeLatest(UserTypes.GET_USER, getUser, api),
        takeLatest(UserTypes.GET_USER_CONTEXT, getUserContext, api),
        takeLatest(UserTypes.GET_USER_PRODUCT_OPTIONS, getUserProductOptions, api),
        takeLatest(UserTypes.SET_USER_ATTRIBUTE, setUserAttribute, api),
        takeLatest(UserTypes.ACTIVATE_ACCOUNT, activateAccount, api),
        takeLatest(UserTypes.EDIT_USER_DETAILS, editUserDetails, api),
        takeLatest(UserTypes.DELETE_MEMBERS, deleteMembers, api),
        takeLatest(UserTypes.VERIFY_TEACHER_EMAIL, verifyTeacherEmail, api),
        takeLatest(UserTypes.GET_USER_INVITE_INFO, getUserInviteInfo, api),
        takeLatest(UserTypes.USE_INVITE_CODE, useInviteCode, api),

        takeLatest(PaymentTypes.SEND_PAYMENT_TOKEN, sendPaymentToken, api),
        takeLatest(PaymentTypes.PROCESS_PAYMENT, processPayment, api),
        takeLatest(PaymentTypes.PROCESS_PAY_PAL_PAYMENT, processPayPalPayment, api),
        takeLatest(PaymentTypes.PROCESS_FREE_TRIAL, processFreeTrial, api),
        takeLatest(PaymentTypes.INVOICE, invoice, api),
        takeLatest(PaymentTypes.ORDER_SUMMARY, orderSummary, api),
        takeLatest(PaymentTypes.PROCESS_ADDRESS_FORM, processAddressForm, api),
        takeLatest(PaymentTypes.CANCEL_ESSENTIALS, cancelEssentialsSubscription, api),
        takeLatest(PaymentTypes.CANCEL_SUBSCRIPTION, cancelSubscription, api),

        takeLatest(ActivationTypes.VERIFY_EMAIL_CODE, verifyEmailCode, api),
        takeLatest(ActivationTypes.GENERATE_CODE, generateCode, api),
        takeLatest(ActivationTypes.CONVERT_TO_FREE, convertToFree, api),
        takeLatest(ActivationTypes.CANCEL_TRIAL, cancelTrial, api),
        takeLatest(ActivationTypes.VERIFY_COUPON, verifyCoupon, api),
        takeLatest(ActivationTypes.LEAVE_INSTANCE, leaveInstance, api),
        takeLatest(ActivationTypes.APPLY_COUPON, applyCoupon, api),

        takeLatest(ProductsTypes.GET_PRODUCTS, getProducts, api),
    ]);
}