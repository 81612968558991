import { call, put } from "redux-saga/effects";
import {PaymentTypes} from "../redux/PaymentReducer";
import * as InitAppHelper from "../helpers/InitAppHelper";
import * as AppHelper from "../helpers/AppHelper";
import {LOCAL_STORAGE_APP_KEY} from "../constants/LocalStorage";
import DataStore from "../helpers/DataStore";
import {getPaymentError} from "../helpers/CaptionHelper";
import {updateLicenseProperties} from "../helpers/InitAppHelper";
import {setRegisterPurchaseTrackingAmount} from "../helpers/GeneralHelper";

const sendPaymentToken = function* (api, action) {
    try {
        const { token } = action;
        const response = yield call(api.sendPaymentToken, token);
        
        if (typeof response.error !== 'undefined') {
        	/* If something went wrong with the API call itself */
            yield put({type: PaymentTypes.SEND_PAYMENT_TOKEN_FAILURE, error: response.error});
            return;
        } 
        
        const payment = response.data;
        
        if(payment.error){
        	/*
			 * This triggers when there's something wrong with the card, for
			 * example a card_declined error code (card is never attached to the
			 * customer object stripe side)
			 */
            yield put({type: PaymentTypes.SEND_PAYMENT_TOKEN_FAILURE, error: getPaymentError(payment.error)});
            return;
        }
        
        /* istanbul ignore else */
        if (action.hasOwnProperty('isTesting') && action.isTesting) {
            payment.isTesting = true;
        } else {
            // this is needed to update the Redux store
            payment.rand = Math.random();
        }
        yield put({type: PaymentTypes.SEND_PAYMENT_TOKEN_SUCCESS, payment: payment});
    } catch (error) {
        yield put({type: PaymentTypes.SEND_PAYMENT_TOKEN_FAILURE, error: error.message});
    }
};

const processPayment = function* (api, action) {
    try {
        const { cartData } = action;
        const response = yield call(api.processPayment, cartData);
        if (response.data.success === false || typeof response.data.success === "undefined") {
            let paymentError = getPaymentError(response.data.error);

            /* istanbul ignore else */
            if(action.hasOwnProperty('isTesting') && action.isTesting) {
                paymentError.isTesting = true;
            } else {
                paymentError.rand = Math.random();
            }

            yield put({type: PaymentTypes.PROCESS_PAYMENT_FAILURE, error: paymentError});
        } else {
        	/* We've successfully upgraded. Need to refetch some relevant information */
        	yield call(api.getProductsInfo);
            yield put({type: PaymentTypes.PROCESS_PAYMENT_SUCCESS, paymentSuccess: response.data.success})
        }
    } catch (error) {
        const paymentError = getPaymentError(null);

        if(action.hasOwnProperty('isTesting') && action.isTesting) {
            paymentError.isTesting = true;
        } else {
            /* istanbul ignore next */
            paymentError.rand = Math.random();
        }

        yield put({type: PaymentTypes.PROCESS_PAYMENT_FAILURE, error: paymentError})
    }
};

const processPayPalPayment = function* (api, action) {
    try {
        const { cartData } = action;
        const response = yield call(api.processPayPalPayment, cartData);
        if (typeof response.error !== 'undefined') {
            yield put({type: PaymentTypes.PROCESS_PAY_PAL_PAYMENT_FAILURE, error: response.error})
        } else {
            yield put({type: PaymentTypes.PROCESS_PAY_PAL_PAYMENT_SUCCESS, paymentPayPalSuccess: response.data})
        }
    } catch (error) {
        yield put({type: PaymentTypes.PROCESS_PAY_PAL_PAYMENT_FAILURE, error: error.message})
    }
};

const processFreeTrial = function* (api, action) {
    try {
        const { vertical, instanceName } = action;
        const response = yield call(api.processFreeTrial, vertical, instanceName);

        yield put({type: PaymentTypes.PROCESS_FREE_TRIAL_SUCCESS, freeTrialSuccess: response.data.success})
    } catch (e) {
        yield put({type: PaymentTypes.PROCESS_FREE_TRIAL_FAILURE, error: e.message})
    }
};

const invoice = function* (api, action) {
    try {
    	let productInfo = AppHelper.getApp().productsInfo || (yield call(api.getProductsInfo));
    	let userLicense = {};
        const invoice = {
                orderId: productInfo.orderId,
                orderDuration: productInfo.orderDuration,
                orderEndDate: productInfo.orderEndDate,
                orderStartDate: productInfo.orderEndDate - productInfo.orderDuration,
                hasFlex: productInfo.productCode.indexOf("flex") >= 0,
                termDurationMonths: productInfo.TermDurationMonths,
                productCode: productInfo.productCode,
                subscriptionName: productInfo.subscriptionName,
                /* istanbul ignore else */
                Seats: productInfo.hasOwnProperty('Seats') ? productInfo.Seats : 0,
                /* istanbul ignore else */
                seatsPrice: productInfo.hasOwnProperty('Seat Price') ? productInfo['Seat Price'] : 0,
                /* istanbul ignore else */
                baseProductPrice: productInfo.hasOwnProperty('baseProductPrice') ? productInfo['baseProductPrice'] : 0,
            };

        if (productInfo.hasOwnProperty('TrialProduct') && productInfo.TrialProduct === 'true') {
            InitAppHelper.setProfessionalTrial(productInfo);
            if (productInfo.hasOwnProperty('Trial Duration')) {
                // updating the remaining trial days for the upgrade success page
                DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'remainingTrialDays', InitAppHelper.setRemainingTrialDays(productInfo, {}));
            }
        }

        let user = {}, paymentInvoice = {};
        try {
            const responseUser = yield call(api.getUser);
            user = responseUser.data;
        } catch (e) {}

        if (user['enterpriseMember'] || user['enterpriseAccountOwner']) {
            userLicense = yield call(api.getUserLicense);
            if(userLicense.hasOwnProperty('data')) {
                invoice.maxSeats = userLicense.data.hasOwnProperty('maxSeats') ? userLicense.data.maxSeats : 0;
                // we need to update the license state with the new data, so that we can display things on the success page
                updateLicenseProperties(AppHelper.getApp(), userLicense.data);
            }
        }

        const response = yield call(InitAppHelper.userHasPayPalPayment(user, productInfo) ? api.getPaymentPayPallInvoice: api.getPaymentInvoice);
        paymentInvoice = response.data[response.data.length - 1];

        invoice.paymentProcessor = InitAppHelper.userHasPayPalPayment(user, productInfo) ? "paypal" : "cc";

        if (typeof paymentInvoice === 'undefined' || (!paymentInvoice.hasOwnProperty('invoiceNumber') && !paymentInvoice.hasOwnProperty('id'))) {
            if (response.data && response.data.constructor === Array && response.data.length === 0) {
                yield put({type: PaymentTypes.INVOICE_FAILURE, error: 'PAYPAL INVOICE WAS NOT GENERATED'});
            } else {
                yield put({type: PaymentTypes.INVOICE_FAILURE, error: 'INVOICE WAS NOT GENERATED'});
            }
        } else {
            invoice.paymentInvoiceId = InitAppHelper.userHasPayPalPayment(user, productInfo) ? paymentInvoice.invoiceNumber : paymentInvoice.id;
            if (invoice.paymentProcessor === "paypal") {
                invoice.price = paymentInvoice.hasOwnProperty('paid') ? paymentInvoice.paid : 0;
            } else {
                invoice.price = paymentInvoice.hasOwnProperty('price') ? paymentInvoice.price : 0;
            }

            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'isUserEnterpriseMember', InitAppHelper.isUserEnterpriseMember(user));
            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'isEnterpriseAdmin', InitAppHelper.setIsEnterpriseAdmin(user));
            DataStore.getInstance().update(LOCAL_STORAGE_APP_KEY, 'userHasPaymentMethodSet', InitAppHelper.userHasPaymentMethodSet(user, productInfo));

            yield put({type: PaymentTypes.INVOICE_SUCCESS, invoice: invoice})
        }
    } catch (e) {
        yield put({type: PaymentTypes.INVOICE_FAILURE, error: e.message})
    }
};

const orderSummary = function* (api, action) {
    try {
        const { targetProduct, seats, addOns } = action;
        const response = yield call(api.getOrderSummary, targetProduct, seats, addOns);
        if (response.data.hasOwnProperty('subTotal')) {
            setRegisterPurchaseTrackingAmount(response.data.subTotal);
        }
        yield put({type: PaymentTypes.ORDER_SUMMARY_SUCCESS, orderSummary: response.data})
    } catch (e) {
        yield put({type: PaymentTypes.ORDER_SUMMARY_FAILURE, error: e.message})
    }
};

const processAddressForm = function* (api, action) {
    try {
        const { addressData } = action;
        const response = yield call(api.processAddressForm, addressData);
        yield put({type: PaymentTypes.PROCESS_ADDRESS_FORM_SUCCESS, addressFormSuccess: response.data.success})
    } catch (e) {
        yield put({type: PaymentTypes.PROCESS_ADDRESS_FORM_FAILURE, error: e.message})
    }
};

const cancelEssentialsSubscription = function* (api, action) {
    try {
        const response = yield call(api.cancelEssentialsSubscription);
        yield put({type: PaymentTypes.CANCEL_ESSENTIALS_SUCCESS, cancelEssentialsSuccess: response.data.success})
    } catch (e) {
        yield put({type: PaymentTypes.CANCEL_ESSENTIALS_FAILURE, error: e.message})
    }
};

const cancelSubscription = function* (api, action) {
    try {
        const response = yield call(api.cancelSubscription);
        yield put({type: PaymentTypes.CANCEL_SUBSCRIPTION_SUCCESS, cancelSubscriptionSuccess: response.data.success})
    } catch (e) {
        yield put({type: PaymentTypes.CANCEL_SUBSCRIPTION_FAILURE, error: e.message})
    }
};

export {
    sendPaymentToken,
    processPayment,
    processPayPalPayment,
    processFreeTrial,
    invoice,
    orderSummary,
    processAddressForm,
    cancelEssentialsSubscription,
    cancelSubscription,
};