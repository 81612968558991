/* istanbul ignore file */
import ApiService from "./ApiService";
import {INSTANCE_NAME} from "../../constants/AppGlobal";
import * as InitAppHelper from "../../helpers/InitAppHelper";
import * as AppHelper from "../../helpers/AppHelper";
import {trackError} from "../track/ErrorTracker";
import {isAuth, isFreeUser} from "../../helpers/AppHelper";

const create = () => {
    const api = new ApiService();

    const getUser = () => {
        return api.callAPI('GET', '/api/2/wevideouser/info/', {
            validateStatus: function (status) {
                return status < 500;
            }});
    };

    const getSessionStatus = () => {
        let sep = "?";
        if (window.location.search.length > 1 && window.location.search.indexOf("?") !== -1) {
            sep = "&";
        }
        const url = "/api/5/session-status" + window.location.search + sep + "initialReferrer=" + encodeURIComponent(document.referrer);

        return api.callAPI('GET', url, {
            validateStatus: function (status) {
                return status < 500;
        }});
    }

    const getUserContext = () => {
        return api.callAPI('GET', '/api/2/wevideouser/context?v=2.1.0&_=' + Date.now());
    };

    const getInstanceProperty = (instanceName) => {
        const name = instanceName || INSTANCE_NAME;
        return api.callAPI('GET', "/movieAPI?method=getInstanceInfo&instanceName=" + name);
    };

    const sendPaymentToken = (token) => {
        return api.callAPI('POST', '/api/3/payment/token', { token: token });
    };

    const processPayment = (cartData) => {
    	cartData.newCart = true;
        if (!cartData.product || !cartData.product.code) {
            console.warn(cartData);
            // login some data to console, for debugging, no GDPR sensitive data
            trackError('Upgrade call without any product');
        }
        return api.callAPI('POST', '/api/3/payment/upgrade', cartData);
    };

    const processPayPalPayment = (cartData) => {
    	cartData.newCart = true;
    	if (!cartData.hasOwnProperty('href')) {
            cartData.href = window.location.href;
        }
        if (!cartData.product || !cartData.product.code) {
            console.warn(cartData);
            // login some data to console, for debugging, no GDPR sensitive data
            trackError('Upgrade call without any product');
        }
        return api.callAPI('POST', '/api/3/payment/paypalsubscription', cartData);
    };

    const getUserLicense = () => {
        return api.callAPI('GET', '/api/2/enterprise/license');
    };

    const getProductsInfo = (app) => {
        return api.callAPI('GET', '/api/2/wevideouser/products').then(resp => {
         	app = app || {};
         	
         	/*
			 * We always want the app object to stay in sync with the most
			 * recent values from the server
			 */
        	InitAppHelper.setProductInformationProperties(app, resp.data);
        	AppHelper.updateApp(app);
        	return resp.data
        });
    };

    const getUserProductOptions = (tier, isNewPlans, promoId, trial) => {
        return getProducts(tier, isNewPlans, promoId, trial);
    };

    const getProducts = (tier, isNewPlans, promoId, trial) => {
        let url = "/api/3/payment/options?tier=" + tier;

        if(typeof isNewPlans !== "undefined" && isNewPlans) {
            url += "&new=true";
        }

        if(typeof promoId !== "undefined" && promoId !== "" && promoId) {
            url += "&promoId=" + promoId;
        }

        if(typeof trial !== "undefined" && trial !== "") {
            url += "&trial=" + trial;
        }
        url += "&newCart=true&_=" + Date.now();

        return api.callAPI('GET', url );
    };

    const getPaymentInvoice = () => {
        return api.callAPI('GET', '/api/3/payment/invoices/1?_=' + Date.now() );
    };

    const getPaymentPayPallInvoice = () => {
        return api.callAPI('GET', '/api/3/payment/paypalinvoices/1?_=' + Date.now() );
    };

    const getUserLocation = () => {
        return api.callAPI('GET', '/api/2/wevideouser/location' );
    };

    const processFreeTrial = (vertical, instanceName) => {
        const data = {
            vertical: vertical,
            instanceName: instanceName,
        };

        return api.callAPI('POST', '/api/3/payment/activatefreetrial', data );
    };

    const verifyEmailCode = (code) => {
        const data = {
            code: code
        };

        return api.callAPI('POST', '/api/4/user/2fa/verifyemailcode', data );
    };

    const generateCode = () => {
        return api.callAPI('POST', '/api/4/user/2fa/emailverification' );
    };

    const convertAccountToFree = (reason) => {
        const data = {
            reason: reason,
            forgetMe: false,
            deleteOwner: false,
        };

        return api.callAPI('POST', '/api/4/instance/disband', data);
    };

    const cancelTrial = (forgetMe) => {
        const data = {
            forgetMe: forgetMe
        };

        return api.callAPI('DELETE', '/api/4/user/', data);
    };

    const getOrderSummary = (targetProduct, seats = 0, addOns = null) => {
        const data = {
            targetProduct: targetProduct,
            seats: seats
        };

        if(typeof addOns !== "undefined" && addOns !== null) {
            data.addOns = addOns;
        }

        return api.callAPI('POST', '/api/4/payment/summary', data );
    };

    const setUserAttribute = (key, value) => {
        return api.callAPI('POST', '/api/2/wevideouser/attribute?key=' + key + '&value=' + value );
    };

    const activateAccount = () => {
        return api.callAPI('POST', '/api/4/user/activate' );
    };

    const editUserDetails = (user) => {
        const data = {
            email: user.email,
            firstName: user.userFirstName,
            lastName: user.userLastName,
            username: user.userName,
        };

        return api.callAPI('PUT', '/api/4/user', data );
    };

    const deleteMembers = (forgetMe, deleteOwner, reason, forgetOwner, deleteMembers) => {
        const data = {
            forgetMe: forgetMe,
            deleteOwner: deleteOwner,
            reason: reason,
        };

        if (typeof forgetOwner !== "undefined") {
            data.forgetOwner = forgetOwner;
        }

        if (typeof deleteMembers !== "undefined") {
            data.deleteMembers = deleteMembers;
        }

        return api.callAPI('POST', '/api/4/instance/disband', data );
    };

    const cancelEssentialsSubscription = () => {
        return api.callAPI('DELETE', '/api/3/payment/essentials');
    };

    const leaveInstance = (instanceId) => {
        return api.callAPI('POST', '/api/4/instance/' + instanceId + "/leave");
    };

    const cancelSubscription = () => {
        return api.callAPI('DELETE', '/api/3/payment');
    };

    const verifyCoupon = (couponCode) => {
        return api.callAPI('POST', '/api/3/instances/verifycoupon/' + couponCode);
    };

    const applyCoupon = (couponCode) => {
        return api.callAPI('POST', '/api/3/instances/applycoupon/' + couponCode);
    };

    const sendTrackJsError = (uriParams, payload) => {
        return api.callAPI('POST', '/api/2/event/trackjs' + uriParams, payload);
    };

    const verifyTeacherEmail = () => {
        return api.callAPI('POST', '/api/4/products/teacherplan/validateuser' );
    };

    const sendMixpanelEvent = (payload) => {
        return api.callAPI('POST', '/api/4/analytics/instrumentation', payload);
    }

    const getUserInviteInfo = (joinCode) => {
        return api.callAPI('GET', '/api/5/invites/' + joinCode);
    }

    const useInviteCode = (joinCode) => {
        let extraRequestUrl = "";
        if (isAuth() && !isFreeUser()) {
            extraRequestUrl = "?cancelSubscription=true";
        }
        return api.callAPI('POST', '/api/5/invites/' + joinCode + extraRequestUrl);
    }

    const processAddressForm = (addressData) => {
        return api.callAPI('POST', '/api/4/payment/address', addressData);
    }

    return {
        getUser,
        getSessionStatus,
        getUserContext,
        sendPaymentToken,
        processPayment,
        processPayPalPayment,
        getUserLicense,
        getProductsInfo,
        getUserProductOptions,
        processFreeTrial,
        getPaymentInvoice,
        getPaymentPayPallInvoice,
        getUserLocation,
        verifyEmailCode,
        generateCode,
        convertAccountToFree,
        cancelTrial,
        getProducts,
        getInstanceProperty,
        getOrderSummary,
        setUserAttribute,
        activateAccount,
        editUserDetails,
        cancelEssentialsSubscription,
        cancelSubscription,
        deleteMembers,
        verifyCoupon,
        leaveInstance,
        applyCoupon,
        sendTrackJsError,
        verifyTeacherEmail,
        sendMixpanelEvent,
        getUserInviteInfo,
        useInviteCode,
        processAddressForm,
    }
};

const apiCreate = {
    create
};

export default apiCreate;
