import Products from "../../models/Products";
import {getProductCode, hasGtmEnabled} from "../../helpers/AppHelper";
import {PLANS_GTM_EVENTS} from "../../constants/Plans";

window.dataLayer = window.dataLayer || [];

const labels = {
    wv_single_1m: "D-82CPqe5nEQzLGQuAM",
    wv_single_12m: "Rae8CJnMznEQzLGQuAM",
    wv_unlimited_1m: "25lwCNDY4HEQzLGQuAM",
    wv_unlimited_12m: "nzi3CILQznEQzLGQuAM",
    wv_flex2: "jZIMCMOo52cQzLGQuAM",
    wv_single: "a9fhCOeE0GcQzLGQuAM",
    wv_k12_12m: "hWwNCOvc42cQzLGQuAM",
    wv_unloct17_1m: "u8VECISmp3kQzLGQuAM",
    wv_unloct17_12m: "_TQcCMGnp3kQzLGQuAM",
    wv_bizoct17_1m: "QS4KCKWpp3kQzLGQuAM",
    wv_bizoct17_12m: "WsIvCK_avnkQzLGQuAM",
    wv_bizindiv_12m: "4hi6CM21iH8QzLGQuAM",
    wv_bizindiv_1m: "4pvWCOmkm38QzLGQuAM",
    wv_single_plus_1m: "WIjfCJCI738QzLGQuAM",
    wv_single_plus_12m: "HMcvCMyL738QzLGQuAM",
    wv_unlimited_plus_1m: "LaSCI6M738QzLGQuAM",
    wv_unlimited_plus_12m: "gMw4CNuL738QzLGQuAM",
    wv_unloct17_plus_1m: "Y_-ICNTphYABEMyxkLgD",
    wv_unloct17_plus_12m: "3t2GCOCN738QzLGQuAM",
    default: "rekkCK-F0GcQzLGQuAM", // If product isn't in dict
};

class TagManagerTrack {
    static registerPurchase = (isUpgrade, newProductCode, newProductName, oldProductCode, orderId, price) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        window.dataLayer.push({
            "event": "PaymentCompleted",
            "Subscription-Price": price,
            "Order-ID": orderId
        });
        
        var label = labels[newProductCode] ? labels[newProductCode] : labels["default"];

        window.dataLayer.push({
            "Subscription-Product-Code": newProductCode,
            "Subscription-Price": price,
            "Order-ID": orderId,
            "Adwords-Label": label,
            "transactionId": orderId,
            "transactionTotal": price,
            "transactionProducts": [
                {
                    "sku": newProductCode,
                    "name": newProductCode,
                    "price": price,
                    "quantity": 1,
                },
            ],
            event: "ecommerce_event",
        });
        
        let newProduct = Products.getInstance().getProductByCode(newProductCode);
        let isEssentialsAddOn = false;
        // on the success page, if the acc is a cc req trial, then we don't have a real product loaded here
        // but we know that isEssentialsAddOn should be false in this case
        if (typeof newProduct !== 'undefined' && newProduct.hasOwnProperty('getEssentialsDowngrade')) {
            isEssentialsAddOn = newProduct.getEssentialsDowngrade() != null && Object.entries(newProduct.getEssentialsDowngrade()).length !== 0;
        }
        window.dataLayer.push({
            'event' : 'purchase',
            'orderId': 'I_' + orderId,
            'orderTotal': price,
            'productName': newProductName,
            'productId': newProductCode,
            'productPrice': price,
            'isEssentialsAddOn': isEssentialsAddOn
        });
        
        /* Added for GTM-2 */
        if(isUpgrade){
        	window.dataLayer.push({
        		"event": "planUpgrade",
        		"newPlan" : newProductCode,
        		"formerPlan": oldProductCode,
        		"planCategory": newProductCode.indexOf("k12") > 0 ? "education" : "personal"
        	})
        }

        if (PLANS_GTM_EVENTS.hasOwnProperty(newProductCode)) {
            window.dataLayer.push({
                "event": PLANS_GTM_EVENTS[newProductCode]
            });
        }
    };

    static onTrialAccountActivated = (productCode) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (typeof productCode === 'undefined' || productCode === null) {
            productCode = getProductCode();
        }
        window.dataLayer.push({
            "event": "Website Conversion",
            "category": "Website Conversion",
            "action": "Start Trial",
            "label": productCode
        });
        if (PLANS_GTM_EVENTS.hasOwnProperty(productCode)) {
            window.dataLayer.push({
                "event": PLANS_GTM_EVENTS[productCode]
            });
        }
    };

    static onAccountActivated = (productCode) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (typeof productCode === 'undefined' || productCode === null || productCode === "") {
            productCode = getProductCode();
        }
        window.dataLayer.push({
            "event": "Website Conversion",
            "category": "Website Conversion",
            "action": "Signup",
            "label": productCode
        });
    };

    static trackABTestStartToDataLayer = (eventName, properties) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (typeof properties !== 'undefined' && properties.hasOwnProperty('testName') && properties.hasOwnProperty('eventVariation')) {
            window.dataLayer.push({
                'abtest': 'variation1'
            });
        }
    };

    static sendABCustomDimension = (dimension, value) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        let obj = {};
        obj[dimension] = value;
        window.dataLayer.push(obj);
    }

    static trackCartPageView = () => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        window.dataLayer.push({
            'event': 'cart_page_view'
        });
    };

    static trackFreeAccountSignup = () => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        window.dataLayer.push({
            'event': 'personal_free_plan_signup'
        });
    }
}

export default TagManagerTrack;