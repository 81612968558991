/* istanbul ignore file */
import axios from 'axios';
import { getApplicationBaseUrl } from '../../config/config.env.variables'
import {getAppVersion, getAppVersionNumber} from "../../helpers/GeneralHelper";

const handleErrorState = (error) => {

    if(typeof error.code !== "undefined" && error.code === "ECONNABORTED") {
        return {
            message : "Api timeout response.",
            statusCode : error.code,
        };
    }

    if (typeof error.response === "undefined") {
        return {
            message : 'NOT FOUND',
            statusCode : 404,
        };
    }

    if(typeof error.response !== "undefined" && typeof error.response.data !== "undefined" && error.response.data !== "") {
        return {
            message : error.response.data['message'],
            statusCode : error.response.data['error_code'],
        };
    }

    if(typeof error.response.statusText !== "undefined" && typeof error.response.status !== "undefined") {
        return {
            message : error.response.statusText,
            statusCode : error.response.status,
        };
    }

    if(typeof error.response.status !== "undefined") {
        return {
            message : '',
            statusCode : error.response.status,
        };
    }

    return {
        message : 'NOT FOUND',
        statusCode : 404,
    };
};

export default class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: getApplicationBaseUrl() || 'http://localhost:3000',
            timeout: 30000,
            headers: {
                "wev-app-version": getAppVersion() + "/" + getAppVersionNumber()
            }
        })
    }

    callAPI = async (method, url, data = {}) => {
        let response = null;
        switch (method) {
            case "GET":
                let apiGetData = await this.getMethod(url, data);
                response = await this.response(apiGetData, "GET", url, data);
                break;
            case "POST":
                let apiPostData = await this.postMethod(url, data);
                response = await this.response(apiPostData, "POST", url, data);
                break;
            case "PUT":
                let apiPutData = await this.putMethod(url, data);
                response = await this.response(apiPutData, "PUT", url, data);
                break;
            case "DELETE":
                let apiDeleteData = await this.deleteMethod(url, data);
                response = await this.response(apiDeleteData, "DELETE", url, data);
                break;
            default:
                break;
        }
        return response !== null ? response : console.warn('Api no response !!!');
    };

    getMethod = async (url, data = {}) => {
        return await this.api
            .get(url, data)
            .catch(function (error) {
                throw handleErrorState(error);
            });
    };

    postMethod = async (url, data = {}) => {
        return await this.api
            .post(url, data)
            .catch(function (error) {
                throw handleErrorState(error);
            });
    };

    putMethod = async (url, data = {}) => {
        return await this.api
            .put(url, data)
            .catch(function (error) {
                throw handleErrorState(error);
            });
    };

    deleteMethod = async (url, data = {}) => {
        return await this.api
            .delete(url, { data: data })
            .catch(function (error) {
                throw handleErrorState(error);
            });
    };

    response = async (response, method, url, data) => {

        return response;
    };
}