/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import TagManager from "react-gtm-module";
import { polyfill } from 'es6-promise';
import $ from "jquery"

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import store from './redux/Store'
import { getTagManagerArgs } from "./helpers/GeneralHelper";

polyfill();
window.jQuery = $;
const history = createBrowserHistory();

let checkoutAppC = window.hasOwnProperty('checkoutAppContext') ? window.checkoutAppContext : {};
let gtmEnabled = true;
if (checkoutAppC.hasOwnProperty("gtmEnabled")) {
    gtmEnabled = checkoutAppC.gtmEnabled;
}

// security issue, were not loading the GTM if the user is on the new password page
if (gtmEnabled && window.location.pathname !== '/newPassword') {
    TagManager.initialize(getTagManagerArgs());
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
