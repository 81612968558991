import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const {Types, Creators} = createActions({
    sendPaymentToken: {},
    sendPaymentTokenSuccess: null,
    sendPaymentTokenFailure: null,

    processPayment: {},
    processPaymentSuccess: null,
    processPaymentFailure: null,

    processPayPalPayment: {},
    processPayPalPaymentSuccess: null,
    processPayPalPaymentFailure: null,

    processFreeTrial: {},
    processFreeTrialSuccess: null,
    processFreeTrialFailure: null,

    cancelEssentials: {},
    cancelEssentialsSuccess: null,
    cancelEssentialsFailure: null,

    cancelSubscription: {},
    cancelSubscriptionSuccess: null,
    cancelSubscriptionFailure: null,

    invoice: {},
    invoiceSuccess: null,
    invoiceFailure: null,

    orderSummary: {},
    orderSummarySuccess: null,
    orderSummaryFailure: null,

    processAddressForm: {},
    processAddressFormSuccess: null,
    processAddressFormFailure: null,
    clearAddressFormSuccess: null,
});

export const PaymentTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
    token: null,
    error: null,
    paymentSuccess: null,
    paymentPayPalSuccess: null,
    freeTrialSuccess: null,
    freeTrialErrorMessage: null,

    cancelEssentialsSuccess: null,
    cancelEssentialsErrorMessage: null,

    cancelSubscription: null,
    cancelSubscriptionErrorMessage: null,

    invoice: {},
    invoiceLoad: false,
    invoiceError: null,

    orderSummary: {},
    orderSummaryError: null,

    addressFormSuccess: {},
    addressFormError: null,
});

/* Selectors */
export const PaymentSelectors = {};

export const sendPaymentTokenReducer = (state) => {
    return {
        ...state,
    };
};
export const sendPaymentTokenSuccessReducer = (state, action) => {
    const {payment} = action;
    return {
        ...state,
        payment: payment,
    };
};
export const sendPaymentTokenFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        error: error,
    };
};

export const processPaymentReducer = (state) => {
    return {
        ...state,
    };
};
export const processPaymentSuccessReducer = (state, action) => {
    const {paymentSuccess} = action;
    return {
        ...state,
        paymentSuccess: paymentSuccess,
    };
};
export const processPaymentFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        error: error,
    };
};

export const processPayPalPaymentReducer = (state) => {
    return {
        ...state,
    };
};
export const processPayPalPaymentSuccessReducer = (state, action) => {
    const {paymentPayPalSuccess} = action;
    return {
        ...state,
        paymentPayPalSuccess: paymentPayPalSuccess,
    };
};
export const processPayPalPaymentFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        error: error,
    };
};

export const processFreeTrialReducer = (state) => {
    return {
        ...state,
    };
};
export const processFreeTrialSuccessReducer = (state, action) => {
    const {freeTrialSuccess} = action;
    return {
        ...state,
        freeTrialSuccess: freeTrialSuccess,
    }
};
export const processFreeTrialFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        freeTrialSuccess: false,
        freeTrialErrorMessage: error,
    };
};

export const cancelEssentialsReducer = (state) => {
    return {
        ...state,
    };
};
export const cancelEssentialsSuccessReducer = (state, action) => {
    const {cancelEssentialsSuccess} = action;
    return {
        ...state,
        cancelEssentialsSuccess: cancelEssentialsSuccess,
    }
};
export const cancelEssentialsFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        cancelEssentialsSuccess: false,
        cancelEssentialsErrorMessage: error,
    };
};

export const cancelSubscriptionReducer = (state) => {
    return {
        ...state,
    };
};
export const cancelSubscriptionSuccessReducer = (state, action) => {
    const {cancelSubscriptionSuccess} = action;
    return {
        ...state,
        cancelSubscriptionSuccess: cancelSubscriptionSuccess,
    }
};
export const cancelSubscriptionFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionErrorMessage: error,
    };
};

export const invoiceReducer = (state) => {
    return {
        ...state,
        invoiceLoad: true,
    };
};
export const invoiceSuccessReducer = (state, action) => {
    const {invoice} = action;
    return {
        ...state,
        invoice: invoice,
        invoiceLoad: false
    }
};
export const invoiceFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        invoiceError: error,
        invoiceLoad: false
    };
};

export const orderSummaryReducer = (state) => {
    return {
        ...state,
    };
};
export const orderSummarySuccessReducer = (state, action) => {
    const {orderSummary} = action;
    return {
        ...state,
        orderSummary: orderSummary,
    }
};
export const orderSummaryFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        orderSummary: null,
        orderSummaryError: error
    };
};

export const processAddressFormReducer = (state) => {
    return {
        ...state,
    };
};
export const processAddressFormSuccessReducer = (state, action) => {
    const {addressFormSuccess} = action;
    return {
        ...state,
        addressFormSuccess: addressFormSuccess,
    }
};
export const processAddressFormFailureReducer = (state, action) => {
    const {error} = action;

    return {
        ...state,
        addressFormSuccess: null,
        addressFormError: error
    };
};
export const clearAddressFormSuccessReducer = (state) => {
    return {
        ...state,
        addressFormSuccess: null
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SEND_PAYMENT_TOKEN]: sendPaymentTokenReducer,
    [Types.SEND_PAYMENT_TOKEN_SUCCESS]: sendPaymentTokenSuccessReducer,
    [Types.SEND_PAYMENT_TOKEN_FAILURE]: sendPaymentTokenFailureReducer,

    [Types.PROCESS_PAYMENT]: processPaymentReducer,
    [Types.PROCESS_PAYMENT_SUCCESS]: processPaymentSuccessReducer,
    [Types.PROCESS_PAYMENT_FAILURE]: processPaymentFailureReducer,

    [Types.PROCESS_PAY_PAL_PAYMENT]: processPayPalPaymentReducer,
    [Types.PROCESS_PAY_PAL_PAYMENT_SUCCESS]: processPayPalPaymentSuccessReducer,
    [Types.PROCESS_PAY_PAL_PAYMENT_FAILURE]: processPayPalPaymentFailureReducer,

    [Types.PROCESS_FREE_TRIAL]: processFreeTrialReducer,
    [Types.PROCESS_FREE_TRIAL_SUCCESS]: processFreeTrialSuccessReducer,
    [Types.PROCESS_FREE_TRIAL_FAILURE]: processFreeTrialFailureReducer,

    [Types.CANCEL_ESSENTIALS]: cancelEssentialsReducer,
    [Types.CANCEL_ESSENTIALS_SUCCESS]: cancelEssentialsSuccessReducer,
    [Types.CANCEL_ESSENTIALS_FAILURE]: cancelEssentialsFailureReducer,

    [Types.CANCEL_SUBSCRIPTION]: cancelSubscriptionReducer,
    [Types.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccessReducer,
    [Types.CANCEL_SUBSCRIPTION_FAILURE]: cancelSubscriptionFailureReducer,

    [Types.INVOICE]: invoiceReducer,
    [Types.INVOICE_SUCCESS]: invoiceSuccessReducer,
    [Types.INVOICE_FAILURE]: invoiceFailureReducer,

    [Types.ORDER_SUMMARY]: orderSummaryReducer,
    [Types.ORDER_SUMMARY_SUCCESS]: orderSummarySuccessReducer,
    [Types.ORDER_SUMMARY_FAILURE]: orderSummaryFailureReducer,

    [Types.PROCESS_ADDRESS_FORM]: processAddressFormReducer,
    [Types.PROCESS_ADDRESS_FORM_SUCCESS]: processAddressFormSuccessReducer,
    [Types.PROCESS_ADDRESS_FORM_FAILURE]: processAddressFormFailureReducer,
    [Types.CLEAR_ADDRESS_FORM_SUCCESS]: clearAddressFormSuccessReducer,
});