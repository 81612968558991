import React from "react";
import {APP_EXTERNAL_URLS} from "../../../constants/AppGlobal";

import './LoginToolbarView.scss';
import {hasGtmEnabled} from "../../../helpers/AppHelper";
import {isExtensionFlow} from "../../../helpers/GeneralHelper";
import URLHelper from "../../../helpers/URLHelper";

export default class LoginToolbarView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideSignup: props.hideSignup || false,
            colorLogo: props.colorLogo || false,
        }
    }
    onButtonClick = () => {
        window.location.href = "/";
    }
    getSignupUrl = () => {
        if (hasGtmEnabled() && !isExtensionFlow()) {
            return APP_EXTERNAL_URLS.NOT_LOGGED_PLANS_PAGE;
        } else if (isExtensionFlow()) {
            let url = APP_EXTERNAL_URLS.SIGN_UP;
            url = URLHelper.addParamToUrl(url, 'origin=rce');

            return url;
        }

        return APP_EXTERNAL_URLS.SIGN_UP;
    }
    render() {
        return (
            <div className="loginToolbar">
                <div className={"logoContainer" + (this.state.colorLogo ? " colorLogo" : "")}>
                    <button title="WeVideo" onClick={this.onButtonClick} />
                </div>
                {
                    hasGtmEnabled() && !this.state.hideSignup && (
                        <a href={this.getSignupUrl()} className="loginButton">
                            Sign Up
                        </a>
                    )
                }
            </div>
        )
    }
}