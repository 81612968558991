import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from "redux-logger";
import thunk from 'redux-thunk';
import TrackJSLogger from "./TrackJSLogger";
import {isProduction} from "../helpers/GeneralHelper";

const store = (rootReducer, rootSaga, state = null) => {

    /* Redux Configuration */
    const middleware = [];
    const enhancers = [];

    /* Async Actions Middleware */
    middleware.push(thunk);

    /* TrackJS Middleware - only in production */
    /* istanbul ignore next */
    if (isProduction()) {
        middleware.push(TrackJSLogger);
    }

    /* Logger Middleware */
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development' && process.env.NODE_ENV !== "test") {
        middleware.push(logger);
    }

    /* Saga Middleware */
    const sagaMonitor = null;

    const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
    middleware.push(sagaMiddleware);

    /* Assemble Middleware */
    enhancers.push(applyMiddleware(...middleware));
    /* istanbul ignore next */
    if(typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    /* istanbul ignore next */
    if(state !== null) {
        enhancers.push(state);
    }

    const store = createStore(
        rootReducer,
        compose(...enhancers),
    );

    // kick off root saga
    let sagasManager = sagaMiddleware.run(rootSaga);

    return {
        store,
        sagasManager,
        sagaMiddleware
    }
}

export default store;