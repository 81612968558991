
class ActiveCampaignBanner {
    id = "";
    imgUrl = "";
    location = "";
    height = "";
    segment = "";
    targetUrl = "";
    key = "";
    hotSpots = [];

    constructor(banner) {
        if(banner && banner.hasOwnProperty('id')) {
            this.id = banner.id;
            this.imgUrl = banner.hasOwnProperty('imgUrl') ? banner.imgUrl : this.imgUrl;
            this.location = banner.hasOwnProperty('location') ? banner.location : this.location;
            this.height = banner.hasOwnProperty('height') ? banner.height : this.height;
            this.segment = banner.hasOwnProperty('segment') ? banner.segment : this.segment;
            this.targetUrl = banner.hasOwnProperty('targetUrl') ? banner.targetUrl : this.targetUrl;
            this.key = banner.hasOwnProperty('key') ? banner.key : this.key;
            this.hotSpots = banner.hasOwnProperty('hotspots') ? banner.hotspots : this.hotSpots;
        }
    }

    setId = (id) => {
        this.id = id;
    };

    getId = () => {
        return this.id;
    };

    setImgUrl = (imgUrl) => {
        this.imgUrl = imgUrl;
    };

    getImgUrl = () => {
        return this.imgUrl;
    };

    setLocation = (location) => {
        this.location = location;
    };

    getLocation = () => {
        return this.location;
    };

    setHeight = (height) => {
        this.height = height;
    };

    getHeight = () => {
        return this.height;
    };

    setSegment = (segment) => {
        this.segment = segment;
    };

    getSegment = () => {
        return this.segment;
    };

    seTargetUrl = (targetUrl) => {
        this.targetUrl = targetUrl;
    };

    getTargetUrl = () => {
        return this.targetUrl;
    };

    setKey = (key) => {
        this.key = key;
    };

    getKey = () => {
        return this.key;
    };

    setHotSpots = (hotSpots) => {
        this.hotSpots = hotSpots;
    };

    getHotSpots = () => {
        return this.hotSpots;
    };
}

export default ActiveCampaignBanner