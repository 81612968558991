import axios from "axios";

class HubSpotTrack {
    static trackHubspotEvent = (type, data, requiredEvent) => {
        let queryParams = "";
        if (requiredEvent && requiredEvent === true) {
            queryParams = "?requiredEvent=true";
        }
        axios.post("/api/4/analytics/hubspotevent/" + type + queryParams, data || {});
    }
}

export default HubSpotTrack