import {TrackJS} from "trackjs";
import API from "../api/Api";
import {isLocalHost, isProduction} from "../../helpers/GeneralHelper";

export const MAX_CALL_QUOTA = 5;
export const updateCallQuota = () => {
    window.maxCallQuota = Math.min(window.maxCallQuota + 1, MAX_CALL_QUOTA);
};

setInterval(updateCallQuota, 5000);

export const initTrackJs = (appName, appVersion) => {
    const apiEndpoint = '/api/2/event/trackjs';
    const tokenId = "75f00b623b794be29ca5b69a54f67fe3";
    const trackJsApp = isProduction() ? 'checkout-app-prod' : 'checkout-app-dev';
    window.maxCallQuota = MAX_CALL_QUOTA;

    const config = {
        token: tokenId,
        enabled: true,
        application: trackJsApp,
        version: appVersion,
        network: {
            enabled: true,
            error: false,
        },
        console: {
            display: isLocalHost()
        },
        onError: (payload) => {
            /* Do we have anything left in our quota? */
            if (window.maxCallQuota-- <= 0) {
                return;
            }
            /*
             * !!!!
             *
             * Warning, do not log the payload to the console. If you have
             * multiple errors the payload size will grow exponentially, since
             * each payload consists of previous log statements
             *
             * !!!!
             */

            /*
             * We remove any log messages that just is previous trackjs errors.
             * These can be pretty large, so no need to include them
             */
            let i = payload.console.length;
            while (--i > -1) {
                if (payload.console[i].message.indexOf(apiEndpoint) !== -1) {
                    payload.console.splice(i, 1);
                }
            }

            // filter errors from malicious scripts
            if (isSpamError(payload.stack, payload.message)) {
                return false;
            }

            // add metadata for standalone apps
            const isStandalone = isRunningStandalone();
            if (isStandalone) {
                payload.metadata.push({ key: "isStandalone", value: true });
            }

            const error = "<ERROR>\n" + payload.message;
            console.log("%c" + error, "color:red");

            // ignore some errors
            const ignore = isIgnoreError(payload.stack, payload.message);
            if (ignore) {
                ignoreError(payload);
            }

            let api = API.create();
            // send error to the server
            let uriParams = '?token=' + tokenId + '&v=' + payload.version;
            api.sendTrackJsError(uriParams, payload).then(evt => {
                console.warn('TrackJs error sent: ', evt);
            });

            // the server pushes the error to trackjs
            return false;
        },
    };
    TrackJS.install(config);
    TrackJS.addMetadata("appName", appName);
    TrackJS.addMetadata("trackjs_code_source", "cart");
};

export const updateUserData = (userId, instanceName) => {
    TrackJS.configure({ userId: "" + userId });
    TrackJS.addMetadata("sessionId", userId + "_" + Date.now());
    TrackJS.addMetadata("instanceName", "" + instanceName);
};

export const updateData = (key, value) => {
    TrackJS.addMetadata(key, "" + value);
};

export const trackError = (type, levelType = 'ERROR') => {
    if (TrackJS.isInstalled()) {
        TrackJS.addMetadata("trackjs_level", levelType);
        TrackJS.track(type);
        TrackJS.removeMetadata("tracks_level");
    }
};

const hasText = (message, text) => {
    return message && message.indexOf(text) > -1;
};

const isSpamError = (stack, msg) => {
    if (
        hasText(stack, "mashfsttest") ||
        hasText(stack, "femurssculler") ||
        hasText(stack, "anyplacetrivial") ||
        hasText(stack, "duckfacedeals") ||
        hasText(stack, "determineyourroad") ||
        hasText(stack, "docketsuncompromising") ||
        hasText(stack, "crashesdisband") ||
        hasText(stack, "slakelegionary") ||
        hasText(stack, "vertamedia") ||
        hasText(stack, "adroll") || // adds
        hasText(stack, "chrome-extension") || // any errors thrown by extensions
        hasText(stack, "ct.pinterest.com") || // pinterest errors
        hasText(stack, "s.pinimg.com")
    ) {
        return true;
    }
    if (hasText(msg, "react/addons") || hasText(msg, "twttr")) {
        return true;
    }
    return false;
};

const isIgnoreError = (stack, msg) => {
    if (
        hasText(msg, "Script error") || // errors thrown by third party scripts
        hasText(msg, "ResizeObserver loop limit exceeded") // errors thrown by ResizeObserver
    ) {
        return true;
    }
    return false;
};

const ignoreError = (payload) => {
    let found = false;
    payload.metadata.forEach((item) => {
        if (item.key === "trackjs_level") {
            item.value = "WARN";
            found = true;
        }
    });
    if (!found) {
        payload.metadata.push({ key: "trackjs_level", value: "WARN" });
    }
};

const isRunningStandalone = () => {
    return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://")
    );
};
