import { call, put } from "redux-saga/effects";
import {ActivationTypes} from "../redux/ActivationReducer";

const verifyEmailCode = function* (api, action) {
    try {
        const { code } = action;
        const response = yield call(api.verifyEmailCode, code);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.VERIFY_EMAIL_CODE_SUCCESS, emailVerified: true});
            } else {
                yield put({type: ActivationTypes.VERIFY_EMAIL_CODE_FAILURE, error: true});
            }
        } else {
            yield put({type: ActivationTypes.VERIFY_EMAIL_CODE_FAILURE, error: true});
        }
    } catch (error) {
        yield put({type: ActivationTypes.VERIFY_EMAIL_CODE_FAILURE, error: true});
    }
};

const generateCode = function* (api, action) {
    try {

        const response = yield call(api.generateCode);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.GENERATE_CODE_SUCCESS, generatedNewCode: true});
            } else {
                yield put({type: ActivationTypes.GENERATE_CODE_FAILURE, error: true});
            }
        } else {
            yield put({type: ActivationTypes.GENERATE_CODE_FAILURE, error: true});
        }
    } catch (error) {
        yield put({type: ActivationTypes.GENERATE_CODE_FAILURE, error: true});
    }
};

const convertToFree = function* (api, action) {
    try {
        const { reason } = action;

        const response = yield call(api.convertAccountToFree, reason);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.CONVERT_TO_FREE_SUCCESS, convertAccountToFree: true});
            } else {
                yield put({type: ActivationTypes.CONVERT_TO_FREE_FAILURE, error: true});
            }
        } else {
            yield put({type: ActivationTypes.CONVERT_TO_FREE_FAILURE, error: true});
        }
    } catch (error) {
        yield put({type: ActivationTypes.CONVERT_TO_FREE_FAILURE, error: true});
    }
};

const cancelTrial = function* (api, action) {
    try {
        const { forgetMe } = action;

        const response = yield call(api.cancelTrial, forgetMe);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.CANCEL_TRIAL_SUCCESS});
            } else {
                yield put({type: ActivationTypes.CANCEL_TRIAL_FAILURE});
            }
        } else {
            yield put({type: ActivationTypes.CANCEL_TRIAL_FAILURE});
        }
    } catch (error) {
        yield put({type: ActivationTypes.CANCEL_TRIAL_FAILURE});
    }
};

const verifyCoupon = function* (api, action) {
    try {
        const { couponCode } = action,
            response = yield call(api.verifyCoupon, couponCode);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.VERIFY_COUPON_SUCCESS, verifyCouponInstanceName: response.data.instanceName});
            } else {
                yield put({type: ActivationTypes.VERIFY_COUPON_FAILURE});
            }
        } else {
            yield put({type: ActivationTypes.VERIFY_COUPON_FAILURE});
        }
    } catch (error) {
        yield put({type: ActivationTypes.VERIFY_COUPON_FAILURE});
    }
};

const leaveInstance = function* (api, action) {
    try {
        const { instanceId } = action,
            response = yield call(api.leaveInstance, instanceId);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.LEAVE_INSTANCE_SUCCESS});
            } else {
                yield put({type: ActivationTypes.LEAVE_INSTANCE_FAILURE});
            }
        } else {
            yield put({type: ActivationTypes.LEAVE_INSTANCE_FAILURE});
        }
    } catch (error) {
        yield put({type: ActivationTypes.LEAVE_INSTANCE_FAILURE});
    }
};

const applyCoupon = function* (api, action) {
    try {
        const { couponCode } = action,
            response = yield call(api.applyCoupon, couponCode);

        if (typeof response.data.success !== 'undefined') {
            if(response.data.success) {
                yield put({type: ActivationTypes.APPLY_COUPON_SUCCESS, reason: response.data.reason});
            } else {
                yield put({type: ActivationTypes.APPLY_COUPON_FAILURE});
            }
        } else {
            yield put({type: ActivationTypes.APPLY_COUPON_FAILURE});
        }
    } catch (error) {
        yield put({type: ActivationTypes.APPLY_COUPON_FAILURE});
    }
};

export {
    verifyEmailCode,
    generateCode,
    convertToFree,
    cancelTrial,
    verifyCoupon,
    leaveInstance,
    applyCoupon,
}