import OrderSummary from "./OrderSummary";
import {showPremiumTemplates} from "../helpers/AppHelper";
import EssentialsUpgrade from "./EssentialsUpgrade";
import ProductDescription from "./ProductDescription";
import {DEFAULT_TRIAL_DURATION} from "../constants/AppGlobal";
import {
    isBusinessPlan,
    isPowerPlan,
    isProductK12,
    isProfessionalPlan,
    isTeacherPlan,
    isUnlimitedPlan
} from "../helpers/PlansHelper";

class Product {
    hasPlan = false;
    code = "";
    name = "";
    subTitle = "";
    description = [];
    price = 0;
    termDuration = 0;
    recurring = false;
    showYearly = false;
    monthlyPrice = 0;
    monthlyDuration = 0;
    campaignDiscountPercent = 0;
    campaignDiscountPercentFound = false;
    essentials = false;
    currentPlan = false;
    seatPrice = 0;
    savingsValue = 0;
    monthlyVersionPrice = 0;
    hasAnnualCampaignPrice = false;
    campaign = {};
    essentialsDowngrade = {};
    essentialsPrice = 0;
    includedSeats = 0;
    seats = 0;
    extraSeats = 0;
    gbPerSeat = 0;
    hoursPerSeat = 0;
    screenCapture = false;
    dualCapture = false;
    trialDuration = -1;
    hasRecurringCampaign = false;
    includedContentTier = 0;

    essentialsUpgrade = new EssentialsUpgrade();
    orderSummary = new OrderSummary();

    constructor(product) {
        if(product && product.hasOwnProperty('code')) {
            this.hasPlan = true;
            this.campaignDiscountPercent = product.campaignDiscountPercent;
            this.code = product.code;
            this.name = product.name;
            this.currentPlan = product.currentPlan;
            this.essentials = product.essentials;
            this.hasAnnualCampaignPrice = product.hasAnnualCampaignPrice;
            this.monthlyDuration = product.monthlyDuration;
            this.monthlyPrice = product.monthlyPrice;
            this.monthlyVersionPrice = product.monthlyVersionPrice;
            this.price = product.price;
            this.recurring = product.recurring;
            this.savingsValue = product.savingsValue;
            this.seatPrice = product.seatPrice;
            this.showYearly = product.showYearly;
            this.subTitle = product.subTitle;
            this.termDuration = product.termDuration;
            this.essentialsPrice = product.essentialsPrice;
            this.campaign = product.campaign;
            this.essentialsDowngrade = product.essentialsDowngrade;
            this.includedSeats = product.includedSeats > 0 ? product.includedSeats : 0;
            this.seats = product.seats;
            this.extraSeats = product.extraSeats;
            this.screenCapture = product.screenCapture;
            this.dualCapture = product.dualCapture;
            this.hasRecurringCampaign = product.hasRecurringCampaign;
            this.includedContentTier = product.hasOwnProperty('includedContentTier') ? product.includedContentTier : 0;
            if (product.hasOwnProperty('gbPerSeat')) {
                this.setGbPerSeat(product.gbPerSeat);
            }
            if (product.hasOwnProperty('hoursPerSeat')) {
                this.setHoursPerSeat(product.hoursPerSeat);
            }
            
            this.trialDuration = product.trialDuration;

            /* istanbul ignore else */
            if(product.description && product.description.length > 0) {
                // if we should show the premium template item
                // then we add it on the first position in the description array
                this.addPremiumTemplateDescriptionItem();
                for (let i = 0 ; i < product.description.length ; i++) {
                    this.description.push(new ProductDescription(product.description[i]));
                }
            }

            /* istanbul ignore else */
            if (product.essentialsUpgrade &&
                product.essentialsUpgrade.hasOwnProperty('code') && product.essentialsUpgrade.code !== '') {
                this.essentialsUpgrade = new EssentialsUpgrade(product.essentialsUpgrade);
            }

            /* istanbul ignore else */
            if (product.hasOwnProperty('campaignDiscountPercentFound')) {
                this.setCampaignDiscountPercentFound(product.campaignDiscountPercentFound);
            }
        }
    }

    isEducation = () => {
        return isProductK12(this.code) || isTeacherPlan(this.code);
    };

    isBusiness = () => {
        return isBusinessPlan(this.code);
    };

    getPrefixedDisplayName = (prefix) => {
        if(this.name.indexOf(prefix) !== 0){
            return prefix + " " + this.name;
        }

        return this.name;
    };

    getBasePlanPriceWithoutAddons = () => {
        if(this.essentialsDowngrade.hasOwnProperty('essentialsPrice')) {
            return this.essentialsDowngrade.essentialsPrice;
        }

        return this.price - this.getEssentialsPrice();
    };

    getCampaignDiscount = () => {
        if(this.campaign.hasOwnProperty('price') && this.price > this.campaign.price) {
            return parseFloat(this.price - this.campaign.price).toFixed(2);
        }

        return 0;
    };

    getTermDurationString = () => {
		if (this.termDuration === 1) {
			return "month";
		} else if (this.termDuration === 6) {
			return "6 months";
		}

		return "year";
	};

    getFormattedTermAdverb = () => {
        if(this.termDuration === 6){
            return "semi-annually";
        }else if(this.termDuration ===1){
            return "monthly";
        }

        return "annually";
    };
    
    getTrialDuration = () => {
        if(this.trialDuration > 0){
        	return this.trialDuration;
        }
        
        return DEFAULT_TRIAL_DURATION;
    };

    getExtraSeatPricePerMonth = () => {
        return this.seatPrice / this.termDuration
    };

    useTieredSeatPricing = () => {
        return this.code === "wv_k12_12m";
    };

    getMonthlyBasePrice = () => {
        if(this.price === 0) {
            return 0;
        }

        return this.price / this.termDuration;
    };

    getBasePrice = () => {
        return this.price;
    };

    getDiscountedPrice = () => {
        return this.orderSummary.getTotal();
    };

    setHasPlan = (hasPlan) => {
        this.hasPlan = hasPlan;
    };

    getHasPlan = () => {
        return this.hasPlan;
    };

    setCampaignDiscountPercent = (campaignDiscountPercent) => {
        this.campaignDiscountPercent = campaignDiscountPercent;
    };

    getCampaignDiscountPercent = () => {
        return this.campaignDiscountPercent;
    };

    setCampaignDiscountPercentFound = (campaignDiscountPercentFound) => {
        this.campaignDiscountPercentFound = campaignDiscountPercentFound;
    };

    getCampaignDiscountPercentFound = () => {
        return this.campaignDiscountPercentFound;
    };

    setCode = (code) => {
        this.code = code;
    };

    getCode = () => {
        return this.code;
    };

    setName = (name) => {
        this.name = name;
    };

    getName = () => {
        return this.name;
    };

    setCurrentPlan = (currentPlan) => {
        this.currentPlan = currentPlan;
    };

    getCurrentPlan = () => {
        return this.currentPlan;
    };

    setDescription = (description) => {
        this.description = description;
    };

    getDescription = () => {
        return this.description;
    };

    setEssentials = (essentials) => {
        this.essentials = essentials;
    };

    getEssentials = () => {
        return this.essentials;
    };

    setHasAnnualCampaignPrice = (hasAnnualCampaignPrice) => {
        this.hasAnnualCampaignPrice = hasAnnualCampaignPrice;
    };

    getHasAnnualCampaignPrice = () => {
        return this.hasAnnualCampaignPrice;
    };

    setMonthlyDuration = (monthlyDuration) => {
        this.monthlyDuration = monthlyDuration;
    };

    getMonthlyDuration = () => {
        return this.monthlyDuration;
    };

    setMonthlyPrice = (monthlyPrice) => {
        this.monthlyPrice = monthlyPrice;
    };

    getMonthlyPrice = () => {
        return this.monthlyPrice;
    };

    setMonthlyVersionPrice = (monthlyVersionPrice) => {
        this.monthlyVersionPrice = monthlyVersionPrice;
    };

    getMonthlyVersionPrice = () => {
        return this.monthlyVersionPrice;
    };

    setPrice = (price) => {
        this.price = price;
    };

    getPrice = () => {
        if(this.orderSummary.getTotal() !== 0 ) {
            return this.orderSummary.getTotal();
        }
        return this.price;
    };

    setRecurring = (recurring) => {
        this.recurring = recurring;
    };

    getRecurring = () => {
        return this.recurring;
    };

    setSavingsValue = (savingsValue) => {
        this.savingsValue = savingsValue;
    };

    getSavingsValue = () => {
        return this.savingsValue;
    };

    setSeatPrice = (seatPrice) => {
        this.seatPrice = seatPrice;
    };

    getSeatPrice = () => {
        return this.seatPrice;
    };

    setShowYearly = (showYearly) => {
        this.showYearly = showYearly;
    };

    getShowYearly = () => {
        return this.showYearly;
    };

    setSubTitle = (subTitle) => {
        this.subTitle = subTitle;
    };

    getSubTitle = () => {
        return this.subTitle;
    };

    setTermDuration = (termDuration) => {
        this.termDuration = termDuration;
    };

    getTermDuration = () => {
        return this.termDuration;
    };

    setCampaign = (campaign) => {
        this.campaign = campaign;
    };

    getCampaign = () => {
        return this.campaign;
    };

    setEssentialsDowngrade = (essentialsDowngrade) => {
        this.essentialsDowngrade = essentialsDowngrade;
    };

    getEssentialsDowngrade = () => {
        return this.essentialsDowngrade;
    };

    setEssentialsPrice = (essentialsPrice) => {
        this.essentialsPrice = essentialsPrice;
    };

    getEssentialsPrice = () => {
        return this.essentialsPrice;
    };

    setIncludedSeats = (includedSeats) => {
        if (includedSeats > 0) {
            this.includedSeats = includedSeats;
        }
    };

    getIncludedSeats = () => {
        return this.includedSeats;
    };

    setSeats = (seats) => {
        this.seats = seats;
    };

    getSeats = () => {
        if(typeof this.seats === "undefined") {
            return 0;
        }
        return this.seats;
    };

    setExtraSeats = (extraSeats) => {
        this.extraSeats = extraSeats;
    };

    getExtraSeats = () => {
        return this.extraSeats;
    };

    setOrderSummary = (orderSummary) => {
        this.orderSummary = new OrderSummary(orderSummary);
    };

    getOrderSummary = () => {
        return this.orderSummary;
    };

    setGbPerSeat = (gb) => {
        this.gbPerSeat = gb;
    };

    getGbPerSeat = () => {
        return this.gbPerSeat;
    };

    setHoursPerSeat = (hours) => {
        this.hoursPerSeat = hours;
    };

    getHoursPerSeat = () => {
        return this.hoursPerSeat;
    };

    setEssentialsUpgrade = (essentialsUpgrade) => {
        this.essentialsUpgrade = new EssentialsUpgrade(essentialsUpgrade);
    };

    getEssentialsUpgrade = () => {
        return this.essentialsUpgrade;
    };

    setScreenCapture = (screenCapture) => {
        this.screenCapture = screenCapture;
    };

    getScreenCapture = () => {
        return this.screenCapture;
    };

    setDualCapture = (dualCapture) => {
        this.dualCapture = dualCapture;
    };

    getDualCapture = () => {
        return this.dualCapture;
    };

    setHasRecurringCampaign = (hasRecurringCampaign) => {
        this.hasRecurringCampaign = hasRecurringCampaign;
    };

    getHasRecurringCampaign = () => {
        return this.hasRecurringCampaign;
    };

    addPremiumTemplateDescriptionItem = () => {
        if (showPremiumTemplates()) {
            let tooltipText = '', hasTooltip = false, dataFor = '', entryText = 'All premium templates';
            if (isBusinessPlan(this.code) || isProfessionalPlan(this.code)) {
                tooltipText = 'Includes Personal, Business and Education templates';
                dataFor = 'tpl_biz';
                hasTooltip= true;
            }
            if (isPowerPlan(this.code) || isUnlimitedPlan(this.code)) {
                entryText = 'All personal templates';
                tooltipText = 'Excludes Business & Education templates';
                dataFor = 'tpl_per'
                hasTooltip = true;
            }
            if (hasTooltip) {
                const premiumTemplatesItem = {
                    icon: 'wallpaper',
                    text: entryText,
                    tooltip: {
                        dataFor: dataFor,
                        hasTooltip: true,
                        text: tooltipText
                    }
                };
                this.description.push(new ProductDescription(premiumTemplatesItem));
            }
        }
    }
}

export default Product;