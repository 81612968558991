/* istanbul ignore file */
import Bowser from "bowser";
import _ from "lodash";

import {
    deviceName,
    getBranchName,
    getURLParameter, isLocalHost, loadedInIframe, isExtensionFlow, getAppVersion, getWebAppVersionNumber
} from "../../helpers/GeneralHelper";
import {
    getInstanceId,
    getOrderState,
    getProductCode,
    getUserId,
    isAuth,
    useHTMLEditor,
    isInHub, getTargetedPricingCampaign, hasGtmEnabled
} from "../../helpers/AppHelper";
import {APP_HASH_PATH_ROUTE, CHROME_RECORDER_APP, HUB_VERSION} from "../../constants/AppGlobal";
import FacebookTrack from "./FacebookTrack";
import TagManagerTrack from "./TagManagerTrack";
import GeneralTrack from "./GeneralTrack";

/**
 * Contextual properties that should be included in all the PaymentFlow-* events. Provided by the hub as a base64 encoded URL parameter
 */
let paymentFlowParameters = {};

class Track {
    static init = () => {
        try {
            // {PaymentFlow-Location: "SidebarUpgradeButton"}
            // btoa(JSON.stringify({"PaymentFlow-Location": "SidebarUpgradeButton"}));
            const flowParameters = getURLParameter("flowParameters");
            if (flowParameters !== "") {
                paymentFlowParameters = JSON.parse(atob(flowParameters));
            }
        } catch (err) {
            console.log("Error parsing flow parameters", err);
        }

        if (hasGtmEnabled() === false) {
            return false;
        }

        // initialize some data layer variables from parent window
        if (loadedInIframe() && !isLocalHost()) {
            window.dataLayer = window.dataLayer || [];
            if (
                window.parent.hasOwnProperty("dataLayer")
                && typeof window.parent.dataLayer !== "undefined"
                && window.parent.dataLayer.length > 0) {
                const parentDataLayer = window.parent.dataLayer;
                for (let i = 0; i < parentDataLayer.length; i++) {
                    if (
                        parentDataLayer[i].hasOwnProperty("OnetrustActiveGroups")
                        || parentDataLayer[i].hasOwnProperty("OptanonActiveGroups")
                        ||
                        (
                            parentDataLayer[i].hasOwnProperty("event")
                            && ["CookiePreferencesLoaded"].includes(parentDataLayer[i].event)
                        )
                    ) {
                        window.dataLayer.push(parentDataLayer[i]);
                    }
                }
            }
        }
    };

    static getEventProperties = (properties) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        /* If we're running the hub locally (on another port) we'll just use our current address... To avoid cross domain issues */
        let localHub = typeof process.env.REACT_APP_HUB_URL !== "undefined";
        let orderState = getOrderState();
        let isModal = false;
        if (loadedInIframe() === true && window.location.href.indexOf("modal=true") !== -1) {
            isModal = true;
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks
        properties["Editor"] = properties["Editor"] || (useHTMLEditor() ? "HTML" : "Flash");
        properties["Product"] = properties["Product"] || getProductCode();
        properties["UserId"] = getUserId();
        properties["OrderState"] = orderState;

        if (isModal === true) {
            properties["Hash"] = window.parent.location.hash;
        } else {
            properties["Hash"] = window.location.hash;
        }

        if (!properties["page_url"]) {
            let iframePageUrl = window.location.href;

            if (isExtensionFlow()) {
                properties["page_url"] = iframePageUrl;
            } else {
                if (!localHub) {
                    iframePageUrl = window.parent.location.origin + window.parent.location.pathname + window.location.hash;
                }
                properties["page_url"] = loadedInIframe() && !localHub ? iframePageUrl : window.location.href;
            }
        }

        if (isModal === true) {
            properties["page_title"] = window.parent.document.title;
        } else {
            properties["page_title"] = loadedInIframe() && !localHub ? window.parent.document.title : window.document.title;
        }

        if (isAuth()) {
            if (isExtensionFlow()) {
                properties["app"] = CHROME_RECORDER_APP;
            }
        }

        return properties;
    };

    static getEventDefaultProperties = () => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        let defaultProperties = {
            app: getAppVersion(),
            branch: getBranchName(),
            code_source: "cart"
        };
        if (isExtensionFlow()) {
            defaultProperties.app = CHROME_RECORDER_APP;
        }

        try {
            const browser = Bowser.getParser(window.navigator.userAgent);

            if (browser) {
                defaultProperties["os_name"] = browser.getOSName();
                defaultProperties["os_version"] = browser.getOSVersion();
                defaultProperties["browser_name"] = browser.getBrowserName();
                defaultProperties["browser_version"] = browser.getBrowserVersion();
                defaultProperties["device_name"] = deviceName(window.navigator.userAgent);
            }

            defaultProperties["app_version"] = getWebAppVersionNumber();
        } catch (err) {
            console.warn("Error parsing default props", err);
        }

        return defaultProperties;
    };

    static indicative = (conf) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        let baseEventName = conf.event,
            eventName = conf.event,
            data = conf.data || {};

        if (!data["Product-Code"] && !(conf.flow === 'payment')) {
            data["Product-Code"] = getProductCode();
        }

        data["Hub-Version"] = HUB_VERSION;

        if (conf.hasOwnProperty('flow')) {
            baseEventName = conf.flow;

            switch(conf.flow){
                case "payment":
                    baseEventName = "PaymentFlow";
                    break;

                case "Template-Creation":
                    break;
                default:
                    return true;
            }

            eventName = baseEventName + "-" + conf.event;
        }

        let renamedData = {};
        const unchangedAttributes = ["promo_code", "training_units", "prof_dev_units", "campaign_name", "element", "page"];

        if (conf.hasOwnProperty('newEventFormat') && conf.newEventFormat) {
            renamedData = data;
        } else {
            // eslint-disable-next-line no-unused-vars
            for (let attr in data) {
                if (data.hasOwnProperty(attr)) {
                    if(isInHub() || ["PaymentFlow"].includes(baseEventName)) {
                        if (unchangedAttributes.includes(attr) || eventName.indexOf("$experiment_started") === 0) {
                            /* Porting some properties over to the new format (without the event name prefix) */
                            renamedData[attr] = data[attr];
                        } else {
                            renamedData[baseEventName + "-" + attr] = data[attr];
                        }
                    } else {
                        renamedData[attr] = data[attr];
                    }
                }
            }
        }
        if (typeof renamedData === 'object' && !renamedData.hasOwnProperty('new_cart')) {
            renamedData.new_cart = 'set';
        }
        if(conf.flow === "payment" && getTargetedPricingCampaign() && getTargetedPricingCampaign() !== "2021_03_price_test") {
            renamedData["promo_code"] = getTargetedPricingCampaign();
        }

        let addToStorage = false;
        if (conf.hasOwnProperty('addToStorage')) {
            addToStorage = conf.addToStorage;
        }

        Track.trackMixpanelEvent(eventName, renamedData, addToStorage);
    };

    /**
     * This method should be used to track something to Mixpanel, from within the application
     *
     * @param eventName
     * @param data
     * @param addToStorage
     */
    static trackEvent = (eventName, data, addToStorage) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (typeof data === 'object' && !data.hasOwnProperty('new_cart')) {
            data.new_cart = 'set';
        }

        Track.trackMixpanelEvent(eventName, data, addToStorage);
    };
    
    static registerPurchase = (isUpgrade, newProductCode, newProductName, oldProductCode, orderId, price) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
    	try{
    		FacebookTrack.registerPurchase(price, newProductCode, orderId);
    		TagManagerTrack.registerPurchase(isUpgrade, newProductCode, newProductName, oldProductCode, orderId, price);
    	}catch(err){
    		console.log("registerPurchase()", err)
    	}
    };

    static trackHub = (location) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (
            !window.hasOwnProperty('isInHub')
            || !(window.hasOwnProperty('isInHub') && !window.isInHub)
        ) {
            let properties = {
                Page: location.hash || location.pathname,
                UserId: getUserId(),
                InstanceId: getInstanceId(),
                Product: getProductCode(),
                OrderState: getOrderState()
            };
            if (properties && properties.Page !== APP_HASH_PATH_ROUTE) {
                // we dont want to track /cart path with this event, since its just an intermediary step to
                // checkout summary. Also we might remove Hub-Page-View event since it seems its obsolete in checkout at least
                Track.trackEvent("Hub-Page-View", properties);
            }
        }
    };

    static getPaymentFlowParameters = () => {
        return paymentFlowParameters;
    };

    /**
     * USE Track.trackEvent(...) or Track.indicative(...) to track something from the app
     * @param eventName
     * @param properties
     * @param addToStorage
     */
    static trackMixpanelEvent = (eventName, properties, addToStorage) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        addToStorage = addToStorage || false;

        let defaultProperties = Track.getEventDefaultProperties() || {};
        properties = properties || {};
        properties = Track.getEventProperties(properties);
        if (properties["app"]) {
            delete defaultProperties["app"];
        }

        /* The payment flow events should also include the contextual parameters provided by the hub */
        if (eventName.indexOf("PaymentFlow") === 0) {
            properties = Object.assign({}, properties, paymentFlowParameters);
        }

        if (properties["Product"]) {
            delete properties["Product"];
        }

        /* We'll also send all events to the GTM data layer, to piggyback on the work we've already done for Indicative events */
        window.dataLayer.push(Object.assign({"event": eventName}, properties));

        if (addToStorage === true) {
            window.sessionStorage.eventQueue = window.sessionStorage.eventQueue || "[]";
            let events = JSON.parse(window.sessionStorage.eventQueue);
            events.push({id: eventName, data: _.extend(properties, defaultProperties)});
            window.sessionStorage.eventQueue = JSON.stringify(events);
        } else {
            GeneralTrack.getInstance().sendMixpanelEvent(eventName, _.extend(properties, defaultProperties));
        }
    }
}

export default Track;